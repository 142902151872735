import Navbar from "./Navibar"
import Footer from "./Footer"
import GoToTop from "./GoToTop"
import ReactGA from 'react-ga4'
import { useEffect } from "react"
import FbPixel from "./FbPixel"
import fc1dt from './fc1dt.jpg'
import fc1 from './fc1.jpg'
import fc11 from './fc11.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import fcb1 from './fcb1.jpg'
import fcb2 from './fcb2.jpg'
import fcb3 from './fcb3.jpg'
import fcb4 from './fcb4.jpg'
import fcb5 from './fcb5.jpg'
import fcb6 from './fcb6.jpg'



const Facial = () => {
    AOS.init();

    useEffect (() => {
        ReactGA.send("pageview");
      }, []);
return(
    <>
<FbPixel />

< Navbar />


<div className=" card text-bg-dark  " >
<img src={fc1dt} className="card-img-top d-none d-md-block " alt="Suvarnas Bridal"></img>
  <img src={fc1} className="card-img-top d-md-none" alt="Suvarnas Bridal"></img>
  <img src={fc11} className="facialTitle d-md-none" alt="Suvarnas Bridal" data-aos="fade-right" data-aos-duration="2000"></img>
  </div>
  <div className="text-center fw-light facialUs rounded-bottom-5">
<h3> WELCOME TO THE BEST FACIAL TREATMENT PLACE IN TOWN.
 <br></br>
 <br></br>
 Experience the ultimate beauty transformation with our 
 exclusive range of effective and budget-friendly facial treatments that deliver remarkable and visible results.

 We are specializes in providing effective, safe and result-oriented facial treatments.
 <br></br>
 <br></br>
 Choose and Book a facial appointment with us today for the best results!
</h3>
 
</div>

<br></br>
<div className="facialChoice">

<div className="card text-bg-dark rounded-4 d-md-none"style={{overflow:"hidden", marginBottom:"2%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/GoldFacial'>
        <img src={fcb1} className="card-img " alt="Suvarnas Bridal"></img>
        </a>
</div>
<div className="card text-bg-dark rounded-4 d-none d-md-block"style={{overflow:"hidden", marginBottom:"2%",width:"50%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/GoldFacial'>
        <img src={fcb1} className="card-img " alt="Suvarnas Bridal" ></img>
        </a>
</div>

<div className="card text-bg-dark rounded-4 d-md-none"style={{overflow:"hidden", marginBottom:"2%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/ChocolateFacial'>
        <img src={fcb2} className="card-img " alt="Suvarnas Bridal"></img>
        </a>
</div>
<div className="card text-bg-dark rounded-4 d-none d-md-block"style={{overflow:"hidden", marginBottom:"2%",width:"50%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/ChocolateFacial'>
        <img src={fcb2} className="card-img " alt="Suvarnas Bridal" ></img>
        </a>
</div>

<div className="card text-bg-dark rounded-4 d-md-none"style={{overflow:"hidden", marginBottom:"2%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/PapayaFacial'>
        <img src={fcb3} className="card-img " alt="Suvarnas Bridal"></img>
        </a>
</div>
<div className="card text-bg-dark rounded-4 d-none d-md-block"style={{overflow:"hidden", marginBottom:"2%",width:"50%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/PapayaFacial'>
        <img src={fcb3} className="card-img " alt="Suvarnas Bridal" ></img>
        </a>
</div>

<div className="card text-bg-dark rounded-4 d-md-none"style={{overflow:"hidden", marginBottom:"2%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/FruitFacial'>
        <img src={fcb4} className="card-img " alt="Suvarnas Bridal"></img>
        </a>
</div>
<div className="card text-bg-dark rounded-4 d-none d-md-block"style={{overflow:"hidden", marginBottom:"2%",width:"50%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/FruitFacial'>
        <img src={fcb4} className="card-img " alt="Suvarnas Bridal" ></img>
        </a>
</div>

<div className="card text-bg-dark rounded-4 d-md-none"style={{overflow:"hidden", marginBottom:"2%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/PearlFacial'>
        <img src={fcb5} className="card-img " alt="Suvarnas Bridal"></img>
        </a>
</div>
<div className="card text-bg-dark rounded-4 d-none d-md-block"style={{overflow:"hidden", marginBottom:"2%",width:"50%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/PearlFacial'>
        <img src={fcb5} className="card-img " alt="Suvarnas Bridal" ></img>
        </a>
</div>

<div className="card text-bg-dark rounded-4 d-md-none"style={{overflow:"hidden", marginBottom:"2%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/VitaminCFacial'>
        <img src={fcb6} className="card-img " alt="Suvarnas Bridal"></img>
        </a>
</div>
<div className="card text-bg-dark rounded-4 d-none d-md-block"style={{overflow:"hidden", marginBottom:"2%",width:"50%"}} data-aos="fade-right" data-aos-duration="1500">
<a href='/VitaminCFacial'>
        <img src={fcb6} className="card-img " alt="Suvarnas Bridal" ></img>
        </a>
</div>
</div>
<GoToTop />
<Footer />

</>

)

}

export default Facial