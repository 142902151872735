import Navibar from "./Navibar"
import Footer from "./Footer"


const PageNotFound = () => {

    return(
 
       
        <>
         <Navibar />

        <div className="pageNotFound">

<h1> Page Not Found</h1>

</div>


</>
    )
}

export default PageNotFound