import React from 'react'
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';
import Navibar from './Navibar';
import Form from 'react-bootstrap/Form';
import FbPixel from './FbPixel';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import GoToTop from './GoToTop';
import Accordion from 'react-bootstrap/Accordion';
import Footer from './Footer';
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel';

import i1 from './i1.jpg'
import i2 from './i2.jpg'
import i3 from './i3.jpg'
import i4 from './i4.jpg'
import i5 from './i5.jpg'
import i6 from './i6.jpg'
import i7 from './i7.jpg'
import i8 from './i8.jpg'
import i9 from './i9.jpg'
import i10 from './i10.jpg'
import i11 from './i11.jpg'
import i12 from './i12.jpg'
import i13 from './i13.jpg'
import i14 from './i14.jpg'
import i15 from './i15.jpg'
import i16 from './i16.jpg'
import i17 from './i17.jpg'
import i18 from './i18.jpg'
import i19 from './i19.jpg'
import i20 from './i20.jpg'
import i21 from './i21.jpg'
import i22 from './i22.jpg'
import i23 from './i23.jpg'
import i24 from './i24.jpg'
import i25 from './i25.jpg'
import i26 from './i26.jpg'
import i27 from './i27.jpg'
import i28 from './i28.jpg'
import i29 from './i29.jpg'
import i30 from './i30.jpg'
import i31 from './i31.jpg'
import i32 from './i32.jpg'
import i33 from './i33.jpg'
import i34 from './i34.jpg'
import i35 from './i35.jpg'
import i36 from './i36.jpg'
import i37 from './i37.jpg'
import i38 from './i38.jpg'
import i39 from './i39.jpg'
import i40 from './i40.jpg'
import i41 from './i41.jpg'
import i42 from './i42.jpg'
import i43 from './i43.jpg'
import i44 from './i44.jpg'
import i45 from './i45.jpg'
import i46 from './i46.jpg'
import i47 from './i47.jpg'
import i48 from './i48.jpg'
import i49 from './i49.jpg'
import i50 from './i50.jpg'
import i51 from './i51.jpg'
import i52 from './i52.jpg'
import i53 from './i53.jpg'
import i54 from './i54.jpg'





const Gallery = () => {

 

  const [show, setShow] = useState(false);

    const handleCloses = () => setShow(false);
    const handleShow = () => setShow(true);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [makeup, setMakeup] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [location, setLocation] = useState("");
    const [travel, setTravel] = useState("");
    const [enquiry, setEnquiry] = useState("");

   

   

    const wsup = "https://wa.me/165027265?text=" 
    + "Name : "+ name + "%0a"
    + "Phone Number : " + phone + "%0a"
    + "MakeUp Type : "+ makeup + "%0a"
    + "Event Date : "+ date + "%0a"
    + "Time To Be Ready : " + time + "%0a"
    + "Location : " + location + "%0a"
    + "Travel : " + travel + "%0a"
    + "Enquiries : " + enquiry + "%0a%0a";


    const message = (e) => {
        e.preventDefault ();
        window.open(wsup, '_blank');
    }

    const navigate = useNavigate();

        const navigateBack = () => {
          navigate(-1);
        };

  const images = [
    i1,i2,i3,i4,i5,i6,i7,i8,i9,i10, i11,
    i12,
    i13,
    i14,
    i15,
    i16,
    i17,
    i18,
    i19,
    i20,
    i21,
    i22,
    i23,
    i24,
    i25,
    i26,
    i27,
    i28,
    i29,
    i30,
    i31,
    i32,
    i33,
    i34,
    i35,
    i36,
    i37,
    i38,
    i39,
    i40,
    i41,
    i42,
    i43,
    i44,
    i45,
    i46,
    i47,
    i48,
    i49,
    i50,
    i51,
    i52,
    i53,
    i54]

    const [open, setOpen] = useState(false);

    const handleClick =(element, index) =>  {
      setIndex(index)
      setOpen(true)
      console.log(element, index)
    }

    const handleClose = () => {
      setOpen(false)
    };

    const [index, setIndex] = useState(0);

    const handleSelect = (selectIndex) => {
      setIndex(selectIndex);
    };

    useEffect (() => {
      ReactGA.send("pageview");
    }, []);

    return (

      <>

<FbPixel />
<Navibar />

<div style={{
            display: 'block',
            height: "100%",
        
            
          }}>

              <div className="card text-bg-dark d-md-none" style={{marginTop:"20%"}}>
 
              <Carousel activeIndex={index}
        
                onSelect={handleSelect}
                indicators = {false}>
    
    {images.map((element) => (   
                <Carousel.Item 
                interval={3000} 
                key={element}>
                  <img className="d-block w-100" src={element}
        
                    alt="Image One" />
                 
        
        
                </Carousel.Item>
    
    ))}
                
             
              </Carousel>
            </div>
            </div>

      <div className="gallery">

        {images.map((element, index)=> (

          <img src={element} 
          style={{width: "30%", padding:"1%", margin:"1%"}}
          // style={{width: element === index? "50%" : "100%"}}
          onClick={()=> handleClick (element, index)} key={index}></img>
        
      ))}

<div className='container bookMu'>
        <button type="button"  className="btn btn-dark m-2" style={{fontSize:"larger"}} onClick={handleShow}>BOOK NOW</button>
        <button type="button"  className="btn btn-dark m-2" style={{fontSize:"larger"}} onClick={navigateBack}>BACK</button>
        </div>

        

<Modal className = "d-lg-none" show={open} onHide={handleClose} size="lg" style={{backgroundColor:"#0f0e0ebd"}}>
            <Modal.Header className="facialUs" closeButton style={{backgroundColor:"#0f0e0ebd"}}>Bridal Gallery</Modal.Header>
            <Modal.Body style={{backgroundColor:"#0f0e0e"}}>
                

              
        <div style={{
            display: 'block',
            height: "100%",
            
          }}>

              <div className="card bg-dark border-0">
 
              <Carousel activeIndex={index}
        
                onSelect={handleSelect}
                indicators = {false}>
    
    {images.map((element) => (   
                <Carousel.Item 
                interval={3000} 
                key={element}>
                  <img className="d-block w-100" src={element}
        
                    alt="Image One" />
                 
        
        
                </Carousel.Item>
    
    ))}
                
             
              </Carousel>
            </div>
            </div>



        
    
              
            </Modal.Body>
            <Modal.Footer style={{backgroundColor:"#0f0e0ebd"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
          </Modal>
      </div>

      <Modal style={{fontFamily:"Cormorant Garamond", color:"antiquewhite"}} show={show} onHide={handleCloses}>
        <Modal.Header style={{backgroundColor:"#0f0e0ebd"}} closeButton >
          <Modal.Title> Makeup Booking Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className=' formStyle'>

        <Form  onSubmit={message}> 
        
        <Form.Group className="mb-3" controlId="formGridName">
          <Form.Label>Name</Form.Label>
          <Form.Control required size="lg" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridPhone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control required size="lg" type='number' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridSelect1">
        <Form.Label>MakeUp Type</Form.Label>
        <Form.Select required size='lg'  value={makeup} onChange={(e) => setMakeup(e.target.value)} aria-label="Default select example">
    <option>Select MakeUp Type</option>
    <option value="wedding MakeUp">Wedding MakeUp</option>
    <option value="Engagament MakeUp">Engagement MakeUp</option>
    <option value="Dinner MakeUp">Dinner Makeup</option>
  </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridDate">
        <Form.Label>Event Date</Form.Label>
        <Form.Control required size="lg" type='date' placeholder="dd-mm-yyyy" value={date} onChange={(e) => setDate(e.target.value)}
  min="2024-03-01" max="2025-12-31" />
      </Form.Group>
     

      <Form.Group className="mb-3" controlId="formGridTime">
        <Form.Label>Time To Be Ready</Form.Label>
        <Form.Control required size="lg" type='time' placeholder="hh:mm" value={time} onChange={(e) => setTime(e.target.value)} />
      </Form.Group>

     

      <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Location</Form.Label>
      <Form.Control placeholder="Event Place" value={location} onChange={(e) => setLocation(e.target.value)}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formGridSelect2">
        <Form.Label>Do We Need To Travel?</Form.Label>
        <Form.Select size='lg'  value={travel} onChange={(e) => setTravel(e.target.value)} aria-label="Default select example">
    <option>Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
    
  </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Enquiries</Form.Label>
        <Form.Control size="lg" as="textarea" placeholder='Message' value={enquiry} onChange={(e) => setEnquiry(e.target.value)} />
        </Form.Group>
  
        <Button  className='formButton' variant="secondary" type="submit">
          Submit
        </Button>
      </Form>

      <div className='formStyle' style={{backgroundColor:"#0f0e0ebd"}}>

      <i>Please take note: You need WhatsApp to send this form to us. </i>
      </div>

       

        </Modal.Body>
        <Modal.Footer style={{backgroundColor:"#0f0e0ebd"}}>
          <Button variant="secondary" onClick={handleCloses}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>

      <GoToTop />

      </>
    )
  
}

export default Gallery