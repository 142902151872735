import navbarImg from './navbar.png'
import naviDt from './naviDt.png'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


const Navibar = () => {

    return (
      <>

{/* <nav className="navbar navbar-expand-lg fixed-top" style={{backgroundColor: "#0000ff0a"}}>
  <div className="container-fluid">
    <a className="navbar-brand" href="/">
    <img className='position-absolute top-0 start-50 translate-middle-x' src={navbarImg} alt="Bootstrap" style={{width:"50%", textAlign:"center"}}></img>
    </a>
    <button className="navbar-toggler" type="btn" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="/">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Features</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/facial">Facial</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown link
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav> */}

 {/* <Navbar expand="lg" className="navbar navbar-expand-lg fixed-top" style={{backgroundColor: "#0000ff0a"}}>

      <Container fluid>
        <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="#action1">Home</Nav.Link>
            <Nav.Link href="#action2">Link</Nav.Link>
            <NavDropdown title="Link" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Something else here
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#" disabled>
              Link
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar> */}

<Navbar expand="lg" className="navbar fixed-top d-none d-md-flex"data-bs-theme="dark" style={{backgroundColor: "#0000ff0a"}}>
      {/* <Container> */}
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{width:"100%", alignItems:"center", justifyContent:"space-around"}}>
          <Nav.Link href="/" ><img  src={naviDt} alt="Bootstrap" style={{marginRight:"0",paddingRight:'0'}}></img></Nav.Link>
            <Nav.Link href="/MakeupSelection" ><h4>MakeUp</h4></Nav.Link>
            <Nav.Link href="/facial" ><h4>Facial</h4></Nav.Link>
            <Nav.Link href="/EyebrowEmbroidery" ><h4>Eyebrow Embroidery</h4></Nav.Link>
            <Nav.Link href="/Pleating" ><h4>Saree Pre-Pleating</h4></Nav.Link>
            <Nav.Link href="/Course" ><h4>Course</h4></Nav.Link>
            <Nav.Link href="/Gallery" ><h4>Bridal Gallery</h4></Nav.Link>
            <Nav.Link href="/" ><h4>&#x1F3E0;Home</h4></Nav.Link>
            {/* <Nav.Link href="#link"><h4>Bridal Gallery</h4></Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>


    <Navbar expand="none" className="navbar fixed-top d-md-none"data-bs-theme="dark" style={{backgroundColor: "#0000ff0a"}}>
      <Container>
        <Navbar.Brand href="/"><img className='position-absolute top-0 start-50 translate-middle-x' src={navbarImg} alt="Bootstrap" style={{width:"50%", textAlign:"center"}}></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/MakeupSelection"><h3>MakeUp</h3></Nav.Link>
            <Nav.Link href="/Facial"><h3>Facial</h3></Nav.Link>
            <Nav.Link href="/EyebrowEmbroidery"><h3>Eyebrow Embroidery</h3></Nav.Link>
            <Nav.Link href="/Pleating"><h3>Saree Pre-Pleating</h3></Nav.Link>
            <Nav.Link href="/Course"><h3>Course</h3></Nav.Link>
            <Nav.Link href="/Gallery"><h3>Bridal Gallery</h3></Nav.Link>
            <Nav.Link href="/"><h3>&#x1F3E0;Home</h3></Nav.Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  





     
 

    </>

    )
}

export default Navibar