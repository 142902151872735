import Navibar from "./Navibar"
import Footer from "./Footer";
import AOS from 'aos';
import { useEffect } from "react";
import ReactGA from 'react-ga4';
import FbPixel from "./FbPixel";
import 'aos/dist/aos.css';
import GoToTop from "./GoToTop";
import sp4 from "./sp4.jpg"
import sp5 from "./sp5.jpg"
import sp6 from './sp6.jpg'
import sp1 from "./sp1.jpg"
import sp2 from "./sp2.jpg"
import sp3 from "./sp3.jpg"


const Pleating = () => {
    AOS.init();

    useEffect (() => {
      ReactGA.send("pageview");
    }, []);
    
    return (
<>

<FbPixel />
<div className="pleating">
< Navibar />
<div className="pleat0">
        


      

            <div className="pleat1 pleat1Dt sticky"> </div>
            <div className="header headerDt">
                <h1 className="header-text" data-aos="fade-right" data-aos-duration="1500" 
                style={{fontSize:"xxX-large",fontFamily: "Cormorant Garamond"}}>
                    SAREE<br></br>  PRE-PLEATING
         </h1>
            </div>
                <div className="text-center fw-light section">
    <h2 > Discover the art of hassle-free elegance with our exclusive Saree Pre-Pleating at Suvarnas Bridal and Hair Styles.
        
     <br></br>
     <br></br>
     <br></br>
     
     Say goodbye to the complexity of saree draping and elevate your saree-wearing experience with perfectly pleated drapes
                and carefully crafted to enhance your style and grace.
     <br></br>
     <br></br>
     <br></br>
     With the starting price from as low as RM30, now you can effortlessly drape a saree and look stunning for any occasion.

     <br></br>
     <br></br>
     Contact us to schedule your consultation for saree pre-pleating today and experience the differences.
    </h2>
    {/* <h3> Our eyebrow embroidery uses newest 
        shading technique to create pixilated dots brows with no harsh front or outline.
     <br></br>
     <br></br>
     Our unique layering technique designed to provide fullness, symmetry shape and defined color tones for a natural eyebrow look.
    </h3> */}

    
     
    </div>

    {/* <div className="buffer"></div> */}
    



           

            <div className="pleat2 sticky"></div>
            <div className="sareeCard">
            <div className="card rounded-5 bg-dark text-white sareePleatCon" style={{ marginTop:"70%"}}>
                
  <img src={sp1} className="card-img rounded-5 " alt="Suvarnas Bridal"/>
  <div className="card-img-overlay ">
 </div>
</div>
</div>
<div className="sareeCard">
            <div className="card rounded-5 bg-dark text-white  sareePleatCon" style={{ marginTop:"50%"}}>
                
  <img src={sp2} className="card-img rounded-5" alt="Suvarnas Bridal"/>
  <div className="card-img-overlay ">
 </div>
</div>
</div>

<div className="sareeCard">
            <div className="card rounded-5 bg-dark text-white sareePleatCon" style={{marginTop:"50%"}}>
                
  <img src={sp3} className="card-img rounded-5 " alt="Suvarnas Bridal"/>
  <div className="card-img-overlay ">
 </div>
</div>
</div>
            <div className="text-center fw-light section"  style={{marginTop:"100%"}}>
            
            <h1>
            <b style={{color:"#B14197"}}>WHY CHOOSE US? </b>
            </h1>

            <br></br>

            <div className='steps2' >
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo text-start'><h3>Our pre-pleated sarees offer effortless draping, 
        saving you time and effort while ensuring a flawless finish.</h3></div>
      </div>

      <br></br>

      <div className='steps2' >
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar'  style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo text-start'><h3>Experience enhanced comfort and freedom of movement
        that drape seamlessly and gracefully, 
        allowing you to move with ease and confidence.</h3></div>
      </div>

      <br></br>

      <div className='steps2' >
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo text-start'><h3>Our pre-pleated sarees offer versatile styling options, 
        allowing you to effortlessly transition from day to night with elegance and poise.
        </h3></div>
      </div>

      <br></br>

      <div className='steps2' >
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo text-start'><h3>Our personalized touches reflect your individual style and preferences, 
        ensuring your saree is a true reflection of your personality.</h3></div>
      </div>
      <br></br>

     
      {/* <div className='steps2' >
      <div className='stepsNumber'>
     
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h2>Let you have the perfect brows!</h2>
</div>
      </div> */}

    
     
    </div>

    {/* <div className="buffer"></div> */}

            <div className="pleat3 sticky"></div>

            <div className="sareeCard">
            <div className="card rounded-5 bg-dark text-white sareePleatCon" style={{ marginTop:"70%"}}>
                
  <img src={sp4} className="card-img rounded-5 " alt="Suvarnas Bridal"/>
  <div className="card-img-overlay ">
 </div>
</div>
</div>
<div className="sareeCard">
            <div className="card rounded-5 bg-dark text-white sareePleatCon" style={{marginTop:"50%"}}>
                
  <img src={sp5} className="card-img rounded-5 " alt="Suvarnas Bridal"/>
  <div className="card-img-overlay ">
 </div>
</div>
</div>

<div className="sareeCard">
            <div className="card rounded-5 bg-dark text-white sareePleatCon" style={{ marginTop:"50%"}}>
                
  <img src={sp6} className="card-img rounded-5 " alt="Suvarnas Bridal"/>
  <div className="card-img-overlay ">
 </div>
</div>
</div>

{/* <div className="sareeCard">
< a href='https://wa.me/165027265' target='blank'><button style={{ marginTop:"50%"}}>Enquire Us</button></a>
</div> */}

{/* <a className="btn btn-primary" href="#" role="button">Link</a> */}

<div className="sareeCard"> 
  <a className="btn sareeButton" href='https://wa.me/165027265' target='blank' role="button">Enquire Us</a>
  
</div>


       


<div className="text-center fw-light section "  style={{marginTop:"100%"}}>
    
     <Footer />
    </div>
    

   

    
       

        </div>
        </div>

        <GoToTop />

        </>
    )
}

export default Pleating