import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4'
import Modal from 'react-bootstrap/Modal';
import GoToTop from './GoToTop';
import ff1 from './ff1.png'
import Navibar from "./Navibar"
import Footer from "./Footer";
import FbPixel from './FbPixel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import tc1 from './tc1.jpg'
import tc11 from './tc11.png'
import tc1dt from './tc1dt.jpg'
import tc2 from './tc2.jpg'
import tc3 from './tc3.jpg'
import tc4 from './tc4.jpg'

const Course = () => {
  AOS.init();
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [course, setCourse] = useState("");
    const [session, setSession] = useState("");
    const [enquiry, setEnquiry] = useState("");
 

 

    const wsup = "https://wa.me/165027265?text=" 
    + "Name : "+ name + "%0a"
    + "Phone Number : " + phone + "%0a"
    + "Course : "+ course + "%0a"
    + "session : " + session + "%0a"
    + "Enquiries : " + enquiry + "%0a%0a";


  const message = (e) => {
      e.preventDefault ();
      window.open(wsup, '_blank');
  };
  useEffect (() => {
    ReactGA.send("pageview");
  }, []);
        

return (

    <>

    
<Navibar />

<FbPixel />

<div className=" card text-bg-dark  " >
  <img src={tc1} className="card-img-top d-md-none" alt="Suvarnas Bridal"></img>
  <img src={tc1dt} className="card-img-top d-none d-md-block" alt="Suvarnas Bridal"></img>
  <img src={tc11} className="facialTitle d-md-none" alt="Suvarnas Bridal" data-aos="fade-right" data-aos-duration="2000"></img>
  </div>
  <div className="embroBody">
  <div className="text-center fw-light facialUs rounded-bottom-5">
<h3> All courses are carried out by myself - 
    I will share all my personal tricks and tips to fast track you to be 
    able to deliver the highest quality work. 
    <br></br>
    <br></br>
    I want you to also enjoy your days with us in a professional but 
    friendly atmosphere to make everyone feel comfortable with small groups only for a more personal experience.
</h3>
 
</div>

<div className="card-dark mb-3  " style={{marginTop:"2%"}}  >
  <div className="row g-0">
    <div className="col-md-6">
      <img src={tc2} className="img-fluid rounded-top-5 d-md-none " alt="Suvarnas Bridal" />
      <img src={tc2} className="img-fluid d-none d-md-block " alt="Suvarnas Bridal" />
    </div>
    <div className="col-md-6 text-start rounded-bottom-5 whyUs">
    <div className="text-center fw-light ">
        <h3>
            <b style={{color:"#fff"}}>PROFESSIONAL BRIDAL COURSE </b>
            </h3>
            </div>
            <br></br>



<div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>HD Make Up (High Definition)</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar'  style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5> Product Knowledge</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5> 4 Types Eye Makeup Tutorial/ Practical 
using Eye Makeup Practice Board 
        </h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>South Indian Wedding Makeover
     </h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Dinner Makeup Look/Evening Gown Concept</h5>
</div>
      </div>

     
      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>7 Different Hairstyles Tutorial</h5>
</div>
      </div>


      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Saree Pre-pleating/Folding Tutorial</h5>
</div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>3 Types of Saree Draping Tutorial</h5>
</div>
      </div>

      
      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Practical Hands on experience</h5>
</div>
      </div>

      <div className="container mt-3 d-grid gap-2 "> 
  <button className="btn btn-primary border-0 facialPrice" type="button" style={{backgroundColor:"#B14197", fontSize:"larger"}} data-aos="zoom-in" data-aos-duration="1500" onClick={handleShow}>Enquire Us</button>
  
</div>

      
    </div>
  </div>
</div>


<div className="card-dark mb-3  " style={{marginTop:"2%"}}  >
  <div className="row g-0 flex-row-reverse" >
    <div className="col-md-6">
    <img src={tc4} className="img-fluid rounded-top-5 d-md-none " alt="Suvarnas Bridal" />
      <img src={tc4} className="img-fluid d-none d-md-block " alt="Suvarnas Bridal" />
    </div>
    <div className="col-md-6 text-start rounded-bottom-5 whyUs">
    <div className="text-center fw-light ">
        <h3>
            <b style={{color:"#fff"}}>PERSONAL MAKEUP CLASS </b>
            </h3>
            </div>
            <br></br>



<div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Latest Skin Care Technique</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar'  style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5> Skin Preparation For Make-Up</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5> Correct Technique of Foundation Selection and Application
        </h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Latest Eyeshadow
     </h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Eyebrow Drawing, Mascara, Eyeliner and Eyelashes</h5>
</div>
      </div>

     
      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Blusher Application Lip Liner and Lipstick Technique</h5>
</div>
      </div>


      <div className="container mt-3 d-grid gap-2 "> 
  <button className="btn btn-primary border-0 facialPrice" type="button" style={{backgroundColor:"#B14197", fontSize:"larger"}} data-aos="zoom-in" data-aos-duration="1500" onClick={handleShow}>Enquire Us</button>
  
</div>
      


    </div>
  </div>
</div>

<div className="card-dark mb-3  " style={{marginTop:"2%"}}  >
  <div className="row g-0">
    <div className="col-md-6">
    <img src={tc3} className="img-fluid rounded-top-5 d-md-none " alt="Suvarnas Bridal" />
      <img src={tc3} className="img-fluid d-none d-md-block " alt="Suvarnas Bridal" />
    </div>
    <div className="col-md-6 text-start rounded-bottom-5 whyUs">
    <div className="text-center fw-light ">
        <h3>
            <b style={{color:"#fff"}}>SAREE PRE-PLEATING WORKSHOP </b>
            </h3>
            </div>
            <br></br>



<div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Measurement Techniques</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar'  style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5> Saree Pinning </h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5> Pre-Pleating 
        </h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Perfect Ironing
     </h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Box Folding </h5>
</div>
      </div>

     
      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#B14197"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Easy draping Tricks</h5>
</div>
      </div>


      
      <div className="container mt-3 d-grid gap-2 "> 
  <button className="btn btn-primary border-0 facialPrice" type="button" style={{backgroundColor:"#B14197", fontSize:"larger"}} data-aos="zoom-in" data-aos-duration="1500" onClick={handleShow}>Enquire Us</button>
  
</div>
      


    </div>
  </div>
</div>

<GoToTop />
    
<Footer />
</div>

<Modal style={{fontFamily:"Cormorant Garamond", color:"antiquewhite"}} show={show} onHide={handleClose}>
        <Modal.Header style={{backgroundColor:"#0f0e0ebd"}} closeButton >
          <Modal.Title> Course Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className=' formStyle'>

        <Form  onSubmit={message}> 
        
          <Form.Group className="mb-3" controlId="formGridName">
            <Form.Label>Name</Form.Label>
            <Form.Control required resize="lg" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
          </Form.Group>
  
          <Form.Group className="mb-3" controlId="formGridPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control size="lg" type='number' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
          </Form.Group>

          

          <Form.Group className="mb-3" controlId="formGridSelect">
        <Form.Label> Course Type</Form.Label>
        <Form.Select size='lg' required value={course} onChange={(e) => setCourse(e.target.value)} aria-label="Default select example">
    <option>Select Course</option>
    <option value="Full Bridal Course">Full Bridal Course</option>
    <option value="Personal Makeup Class">Personal Makeup Class</option>
    <option value="Sree Pre-Pleating Class">Sree Pre-Pleating Class</option>
   
  </Form.Select>
        </Form.Group>
       
  
        <Form.Group className="mb-3" controlId="formGridSelect2">
        <Form.Label> Session Type</Form.Label>
        <Form.Select size='lg' required value={session} onChange={(e) => setSession(e.target.value)} aria-label="Default select example">
    <option>Select Session</option>
    <option value="Group Class">Group Class</option>
    <option value="Private 1 - 1 ">Private 1 - 1 </option>
   
  </Form.Select>
        </Form.Group>
  
       
  

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Enquiries</Form.Label>
        <Form.Control size="lg" as="textarea" placeholder='Message' value={enquiry} onChange={(e) => setEnquiry(e.target.value)} />
        </Form.Group>
  
        <Button  className='formButton' variant="secondary" type="submit">
          Submit
        </Button>
      </Form>

      <div className='formStyle' style={{backgroundColor:"#0f0e0ebd"}}>

      <i>Please take note: You need WhatsApp to send this form to us. </i>
      </div>

       

        </Modal.Body>
        <Modal.Footer style={{backgroundColor:"#0f0e0ebd"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
  </>
)

}

export default Course