import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4'
import GoToTop from './GoToTop';
import Modal from 'react-bootstrap/Modal';
import Navibar from "./Navibar";
import Accordion from 'react-bootstrap/Accordion';
import ee1 from "./ee1.gif"
import ee11 from './ee11.png'
import ee1dt from './ee1dt.jpg'
import ee2 from './ee2.jpg'
import ee2m from './ee2m.jpg'
import ee3m from './ee3m.jpg'
import eec1 from './eec1.jpg'
import eec2 from './eec2.jpg'
import eec3 from './eec3.jpg'
import eec4 from './eec4.jpg'
import eec5 from './eec5.jpg'
import eec6 from './eec6.jpg'
import eec7 from './eec7.jpg'
import eec8 from './eec8.jpg'
import eec9 from './eec9.jpg'
import eec10 from './eec10.jpg'


import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "./Footer";
import FbPixel from './FbPixel';


const EyebrowEmbroidery = () => {

    AOS.init();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [enquiry, setEnquiry] = useState("");

   

   

    const wsup = "https://wa.me/165027265?text=" 
    + "Name : "+ name + "%0a"
    + "Phone Number : " + phone + "%0a"
    + "Preferred Date : "+ date + "%0a"
    + "Preferred Time : " + time + "%0a"
    + "Enquiries : " + enquiry + "%0a%0a";


    const message = (e) => {
        e.preventDefault ();
        window.open(wsup, '_blank');
    }

    useEffect (() => {
      ReactGA.send("pageview");
    }, []);

    return(
        <>
    
<FbPixel />
    < Navibar />
    
    
    <div className=" card text-bg-dark  " >
      <img src={ee1} className="card-img-top d-md-none" alt="Suvarnas Bridal"></img>
      <img src={ee1dt} className="card-img-top d-none d-md-block " alt="Suvarnas Bridal"></img>
      <img src={ee11} className="facialTitle d-md-none" alt="Suvarnas Bridal" data-aos="fade-right" data-aos-duration="2000"></img>
      
      </div>
      <div className="embroBody">
      <div className="text-center fw-light facialUs rounded-bottom-5">
    <h3> Enhance your natural beauty with perfectly well-shaped Eyebrow. 
        
     <br></br>
     <br></br>
     
     Our eyebrow embroidery uses newest 
        shading technique to create pixilated dots brows with no harsh front or outline.
     <br></br>
     <br></br>
     Our unique layering technique designed to provide fullness, symmetry shape and defined color tones for a natural eyebrow look.
    </h3>
     
    </div>


    <div className="card-dark mb-3  " style={{marginTop:"2%"}}  >
  <div className="row g-0">
    <div className="col-md-6">
      <img src={ee2m} className="img-fluid rounded-top-5 d-md-none" alt="Suvarnas Bridal" />
      <img src={ee2m} className="img-fluid d-none d-md-block" alt="Suvarnas Bridal" />
    </div>
    <div className="col-md-6 text-start rounded-bottom-5 whyUs">
    <div className="text-center fw-light ">
        <h3>
            <b style={{color:"#fff"}}>WHAT ARE THE ADVANTAGES OF EYEBROW EMBROIDERY? </b>
            </h3>
            </div>



<div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#823412"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>No more unbalance brows</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar'  style={{backgroundColor:"#823412"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5> No rubbing off when you go swimming</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#823412"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5> Enjoy smudge-free sports and workouts
        </h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#823412"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Save your time and have a fuss free morning</h5></div>
      </div>

     
      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#823412"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h5>Let you have the perfect brows!</h5>
</div>
      </div>

      


    </div>
  </div>
</div>




<div className="card-dark mb-3  " style={{marginTop:"2%"}}  >
  <div className="row g-0 flex-row-reverse">
    <div className="col-md-6">
    <img src={ee3m} className="img-fluid rounded-top-5 d-md-none" alt="Suvarnas Bridal" />
      <img src={ee3m} className="img-fluid d-none d-md-block" alt="Suvarnas Bridal" />
    </div>
    <div className="col-md-6 text-start rounded-bottom-5 whyUs">
    <div className="text-center fw-light ">
        <h3>
            <b style={{color:"#fff"}}>WHAT ARE THE PROCESS? </b>
            </h3>
            </div>

            <div className='steps'data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber' >
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#823412"}}>1</p>
      </div>
      <div className='stepsInfo'><h5>You will be given the professional consultation and advises. 
      </h5></div>
      </div>

      <div className='steps' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#823412"}}>2</p>
      </div>
      <div className='stepsInfo'><h5>We will analyse your brows and skin type and suggest what brow shape and colour would suit you best.</h5></div>
      </div>

      <div className='steps' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#823412",alignItems:"normal"}}>3</p>
      </div>
      <div className='stepsInfo'><h5>Deep cleanse will be done and mapped out to get the perfect symmetry using the golden ratio formula.</h5></div>
      </div>

      <div className='steps' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#823412",alignItems:"normal"}}>4</p>
      </div>
      <div className='stepsInfo'><h5>Numbing Cream will be applied and 15 to 20mins are needed for the numbing to take effect.</h5></div>
      </div>

      <div className='steps' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#823412",alignItems:"normal"}}>5</p>
      </div>
      <div className='stepsInfo'><h5>Embroidery works will be started.</h5></div>
      </div>

      </div>
  </div>
</div>



<div className="card bg-dark rounded-5 p-3">
      <div className='embroEg' data-aos="fade-up" data-aos-duration="1500">
      <div className='embroBefore' >
      <img src={eec1} style={{width:"100%", padding:"5%"}}></img>
      
      </div>
      <div className='embroAfter'><img src={eec2} style={{width:"100%", padding:"5%"}} 
      data-aos="fade-right" data-aos-delay="1200" data-aos-duration="2500"></img></div>
      </div>

      <div className='embroEg' data-aos="fade-up" data-aos-duration="1500">
      <div className='embroBefore' >
      <img src={eec3} style={{width:"100%", padding:"5%"}}></img>
      
      </div>
      <div className='embroAfter'><img src={eec4} style={{width:"100%", padding:"5%"}}
      data-aos="fade-right" data-aos-delay="1200" data-aos-duration="2500"></img></div>
      </div>

      <div className='embroEg' data-aos="fade-up" data-aos-duration="1500">
      <div className='embroBefore' >
      <img src={eec5} style={{width:"100%", padding:"5%"}} ></img>
      
      </div>
      <div className='embroAfter'><img src={eec6} style={{width:"100%", padding:"5%"}}
      data-aos="fade-right" data-aos-delay="1200" data-aos-duration="2500"></img></div>
      </div>

      <div className='embroEg' data-aos="fade-up" data-aos-duration="1500">
      <div className='embroBefore' >
      <img src={eec7} style={{width:"100%", padding:"5%"}}></img>
      
      </div>
      <div className='embroAfter'><img src={eec8} style={{width:"100%", padding:"5%"}}
      data-aos="fade-right" data-aos-delay="1200" data-aos-duration="2500"></img></div>
      </div>

      <div className='embroEg' data-aos="fade-up" data-aos-duration="1500">
      <div className='embroBefore' >
      <img src={eec9} style={{width:"100%", padding:"5%"}}></img>
      
      </div>
      <div className='embroAfter'><img src={eec10} style={{width:"100%", padding:"5%"}}
      data-aos="fade-right" data-aos-delay="1200" data-aos-duration="2500"></img></div>
      </div>

      </div>


      <div className="container">

      <div className="card text-center rounded-5 mt-3 bg-dark facialPrice">
      <div className="card-header rounded-top-5 " style={{fontSize:"xx-large",backgroundColor:"#823412"}}>PROMOTION PRICE</div>
  <div className="card-body">
  <div className="card-header" data-aos="fade-right" data-aos-duration="1500" style={{fontSize:"xx-large",fontFamily: "Petit Formal Script"}}>RM 250 only</div>

<div className='text-start steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='circleBar'  style={{backgroundColor:"transparent"}}>&#10004;</p>
      </div>
      <div className='stepsInfo' ><h3>Free one-time touch-up</h3></div>
      </div>

      <div className='text-start steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='circleBar'  style={{backgroundColor:"transparent"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h3>Over 15 years experienced eyebrow specialists</h3></div>
      </div>

      <div className='text-start steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='circleBar'  style={{backgroundColor:"transparent"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h3>Free recovery post-care Vitamin C cream</h3></div>
      </div>

      <div className='text-start steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='circleBar'  style={{backgroundColor:"transparent"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h3>Private rooms to ensure your utmost privacy and comfort</h3></div>
      </div>

      <div className='text-start steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='circleBar'  style={{backgroundColor:"transparent"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h3>Individual needles (all done by one-time use needle)</h3></div>
      </div>

      <div className='text-start steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='circleBar'  style={{backgroundColor:"transparent"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h3>We only use safe and hygienic, 100% pure plant material</h3></div>
      </div>

      <div className='text-start steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='circleBar'  style={{backgroundColor:"transparent"}}>&#10004;</p>
      </div>
      <div className='stepsInfo'><h3>Free Consultation</h3></div>
      </div>
      
    <div className="d-grid gap-2"> 
  <button className="btn btn-primary border-0" type="button" style={{backgroundColor:"#823412",fontSize:"xx-large"}} data-aos="zoom-in" data-aos-duration="1500" onClick={handleShow}>Book Now</button>
  
</div>
  </div>
</div>

</div>

<br></br>

<div className='faq'>
<Accordion 
// defaultActiveKey="0"
>
      <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header >HOW DO I PREPARE FOR MY APPOINTMENT?</Accordion.Header>
        <Accordion.Body>
        • Avoid consuming alcohol or energy drinks 24 hours prior to your appointment.
        <br></br>
• Avoid taking Aspirin, Niacin, Advil, Ibuprofen, or any blood thinning medications for 48 hours before the appointment.
 (It is advisable to consult with your doctor before discontinuing any prescription medication).
 <br></br>
• Refrain from tinting your eyebrows for three weeks before the appointment.
<br></br>
• Avoid waxing, shaving, or plucking your eyebrows before the appointment.
<br></br>
• Stay away from excessive sun exposure or tanning for two weeks prior to the appointment.   
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>DOES THE PROCEDURES HURT?</Accordion.Header>
        <Accordion.Body>
        We will apply a superior numbing cream to the treatment area during the procedure to ensure the comfort of you, 
        but it may not guarantee a completely painless experience.
        
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>IS IT SAFE?</Accordion.Header>
        <Accordion.Body>
        We are always safety come first. Each tool comes individually sealed, 
        sterilized and will be disposed off after the treatment. We never reuse it at another customer.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>WHY DO I NEED TO RETURN FOR A TOUCH UP?</Accordion.Header>
        <Accordion.Body>
        Touch up is a necessary session for enhancement, adjustment of the shape, color and strokes of the eyebrow, 
        it will also extend the life span to 1 year depends on individual’s skin condition.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4"data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>WILL YOU SHAVE OFF ALL MY EYEBROW HAIRS? </Accordion.Header>
        <Accordion.Body>
        No, we do not remove all of your eyebrow hairs. We aim to preserve as many of your natural hairs as possible.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>HOW LONG DOES IT TAKE TO HEAL? WILL I BE ABLE TO GO TO WORK?</Accordion.Header>
        <Accordion.Body>
        The healing period for the procedure typically lasts for about 7 days and does not require any significant time off from daily activities.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>HOW LONG WILL IT LAST?</Accordion.Header>
        <Accordion.Body>
        This will be base on each customer’s choice of color, shape, 
        and after care varies, with a touch up within 1 months from the first treatment session, 
        it can be extended to 1 year depends on individual’s skin condition.
        </Accordion.Body>
      </Accordion.Item>
      
    </Accordion>

    </div>

    
<GoToTop />

    <Footer />
      
    </div>   

    <Modal style={{fontFamily:"Cormorant Garamond", color:"antiquewhite"}} show={show} onHide={handleClose}>
        <Modal.Header style={{backgroundColor:"#0f0e0ebd"}} closeButton >
          <Modal.Title> Eyebrow Embro Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className=' formStyle'>

        <Form  onSubmit={message}> 
        
          <Form.Group className="mb-3" controlId="formGridName">
            <Form.Label>Name</Form.Label>
            <Form.Control required resize="lg" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
          </Form.Group>
  
          <Form.Group className="mb-3" controlId="formGridPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control size="lg" type='number' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
          </Form.Group>

          

          <Form.Group className="mb-3" controlId="formGridDate">
          <Form.Label>Preferred Date</Form.Label>
          <Form.Control size="lg" type='date' required placeholder="dd-mm-yyyy" value={date} onChange={(e) => setDate(e.target.value)}
		min="2024-03-01" max="2025-12-31" />
        </Form.Group>
       
  
        <Form.Group className="mb-3" controlId="formGridTime">
          <Form.Label>Preferred Time</Form.Label>
          <Form.Control size="lg" required type='time' placeholder="hh:mm " value={time} onChange={(e) => setTime(e.target.value)} />
        </Form.Group>
  
       
  

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Enquiries</Form.Label>
        <Form.Control size="lg" as="textarea" placeholder='Message' value={enquiry} onChange={(e) => setEnquiry(e.target.value)} />
        </Form.Group>
  
        <Button  className='formButton' variant="secondary" type="submit">
          Submit
        </Button>
      </Form>

      <div className='formStyle' style={{backgroundColor:"#0f0e0ebd"}}>

      <i>Please take note: You need WhatsApp to send this form to us. </i>
      </div>

       

        </Modal.Body>
        <Modal.Footer style={{backgroundColor:"#0f0e0ebd"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>



      


    

</>


    )
}

export default EyebrowEmbroidery