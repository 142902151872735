import React from 'react'
// import TestimonialItem from './TestimonialItem';
 import review from './review'
 import reviewDt from './reviewDt';

 
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel';
import ButtonGroup from "react-bootstrap/ButtonGroup"; 
import Button from "react-bootstrap/Button"; 
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';

import { useState } from 'react';


const FeedBack = () => {

    const [index, setIndex] = useState(0); 

  const handleSelect = (selectedIndex) => { 
      setIndex(selectedIndex); 
  }; 
    

   {
    return (

      <>
      
      <div className='feedBackBg rounded-5 d-none d-md-block '> 
       
    

    <div style={{
        display: 'block',
        height: "100%",
        // position: "absolute",
        // zIndex: 100
    
      }}>
          
          <Carousel activeIndex={index}
            onSelect={handleSelect}
            indicators = {false}>

                {reviewDt.map(element=> (
            <Carousel.Item interval={3000} key={element.id}>
              <img className="d-block w-100" src={element.wsup}
    
                alt="Image One" />
              <h3 className="card text-center fw-light fb1Dt" >{element.feed}</h3>
    
    
            </Carousel.Item>

))}
            
         
          </Carousel>
        </div>
        </div>


<div className='feedBackBgMb rounded-5 d-md-none '> 
<h1 className='fbTitle'> Client's Feedback </h1>


<div style={{
display: 'block',
height: "100%",
// position: "absolute",
// zIndex: 100

}}>
  
  <Carousel activeIndex={index}
    onSelect={handleSelect}
    indicators = {false}>

        {review.map(element=> (
    <Carousel.Item interval={3000} key={element.id}>
      <img className="d-block w-100" src={element.wsup}

        alt="Image One" />
      <h3 className="card text-center fw-light fb1" >{element.feed}</h3>


    </Carousel.Item>

))}
    
 
  </Carousel>
</div>
</div>

        
    
    
</>

    )
    }
  }

export default FeedBack

