import fbm1 from './fbm1.png'
import fbm2 from './fbm2.png'
import fbm3 from './fbm3.png'
import fbm4 from './fbm4.png'
import fbm5 from './fbm5.png'
import fbm6 from './fbm6.png'
import fbm7 from './fbm7.png'
import fbm8 from './fbm8.png'
import fbm9 from './fbm9.png'
import fbm10 from './fbm10.png'


const review =   

         [

            {"id":1,  
            "wsup": fbm1, 
            "feed": "wow akka lovely, seriosly damn nice. Thank you so much akka"},
            {"id":2,
                "name": "Sudar", 
            "wsup": fbm2, 
            "feed": "All said that my makeup was so beautiful n I look nice.. thank you for your makeup"},
            {"id":3,
        "wsup":fbm3, 
        "feed": "Akka Bridal makeup compliment too good from everyone "},
        {"id": 4,
    "wsup": fbm4,
"feed": "Your touch made me glow yesterday, everyone loves ur work. Great job sis. Thanks again"},
        {"id":5,
    "wsup":fbm5,
    "feed":"Thanks alot for the beautiful makeup. And I felt so much comfortable with you"
},
{"id":6,
"wsup":fbm6,
"feed":"criuzly u made my day aka.. ur makeover was criuzly damn nice aka. They even told  i look so natural even after makeup",
},
{"id":7,
"wsup":fbm7,
"feed": "Thank you so much for dolling me on time akka. you' ve magic on on ur fingers. It was so pleasant to know you akka"},
{"id": 8,
"wsup":fbm8,
"feed": "Thank you from the bottom of my heart for making me to look great beyond my expection. Your handwork was a magic"},
{"id":9,
"wsup":fbm9,
"feed":"Nite make over remove panne manase varle sis"
} ,
{"id":10,
"wsup":fbm10,
"feed":"It was soo amazing sis, My parents n family members all say reallluy nice sis"}          
        ]

        


export default review




