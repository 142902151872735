import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';

const EnquiryForm = () => {
    
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [course, setCourse] = useState("");
    const [session, setSession] = useState("");
    const [enquiry, setEnquiry] = useState("");

   

   

    const wsup = "https://wa.me/165027265?text=" 
    + "Name : "+ name + "%0a"
    + "Phone Number : " + phone + "%0a"
    + "Course : "+ course + "%0a"
    + "session : " + session + "%0a"
    + "Enquiries : " + enquiry + "%0a%0a";


    const message = (e) => {
        e.preventDefault ();
        window.open(wsup, '_blank');
    }



    

    return (
<>
        <Form className=' facialUs' onSubmit={message}> 
        
          <Form.Group className="mb-3" controlId="formGridName">
            <Form.Label>Name</Form.Label>
            <Form.Control required resize="lg" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
          </Form.Group>
  
          <Form.Group className="mb-3" controlId="formGridPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control size="lg" type='number' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
          </Form.Group>

          

          <Form.Group className="mb-3" controlId="formGridSelect">
        <Form.Label> Course Type</Form.Label>
        <Form.Select size='lg' required value={course} onChange={(e) => setCourse(e.target.value)} aria-label="Default select example">
    <option>Select Course</option>
    <option value="Full Bridal Course">Full Bridal Course</option>
    <option value="Personal Makeup Class">Personal Makeup Class</option>
    <option value="Sree Pre-Pleating Class">Sree Pre-Pleating Class</option>
   
  </Form.Select>
        </Form.Group>
       
  
        <Form.Group className="mb-3" controlId="formGridSelect">
        <Form.Label> Session Type</Form.Label>
        <Form.Select size='lg' required value={session} onChange={(e) => setSession(e.target.value)} aria-label="Default select example">
    <option>Select Session</option>
    <option value="Group Class">Group Class</option>
    <option value="Private 1 - 1 ">Private 1 - 1 </option>
   
  </Form.Select>
        </Form.Group>
  
       
  

        <Form.Group className="mb-3" id="formGridTextArea">
        <Form.Label>Enquiries</Form.Label>
        <Form.Control size="lg" as="textarea" placeholder='Message' value={enquiry} onChange={(e) => setEnquiry(e.target.value)} aria-label="With textarea" />
      </Form.Group>
  
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>

      <div className='facialUs'>

      <i>Please take note: You need WhatsApp to send this form to us. </i>
      </div>
</>
     




    )
    
}



export default EnquiryForm