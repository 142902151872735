import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


 

import navbarImg from './navbar.png'
import waze from './waze.svg'
import map from './map.svg'
import whatsapp from "./whatsapp.svg"
import call from './call.svg'
import mail from './mail.svg'
import insta  from './ig.png'
import fb from './fb.png'
import tiktok from './ttk.png'


const Footer = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [shows, setShows] = useState(false);

    const handleCloses = () => setShows(false);
    const handleShows = () => setShows(true);

    return (
        <>

        
<div className='mt-3 d-md-none '>
        <div className="footerLogo text-center rounded-top-5 facialUs">
            <img src={navbarImg} style={{width:"50%"}}></img>
        </div>


        <ul className='text-center fw-light facialUs' style={{listStyleType:"none", marginBottom:'0'}}>
            <li className='footerBreak'>
                <p className='breakLine'></p>
                <h1 className='FooterTitle'>Contact Us</h1>
                
            </li>

            <li style={{marginTop:"10%"}}>< a href='tel:+60165027265' target='blank'><img src={call}style={{width:"15%"}}></img></a>
</li>
                    <li>(016)5027265</li>
                    <li>< a href='mailto:enquiry@suvarnasbridal.my' target='blank'><img src={mail} style={{width:"15%"}}></img></a>
</li>
<li> suvarna.sdnbhd@gmail.com</li>
<li>< a href='https://wa.me/165027265' target='blank'><img src={whatsapp} style={{width:"15%"}}></img></a>
</li>
<li>https://wa.me/165027265</li>
<li><a href='https://waze.com/ul/hw0zf56gnk' target='blank'><img src={waze} style={{width:"15%",marginRight:"8%"}}></img></a>
<a href='https://maps.app.goo.gl/FqgRjg2sLB3Canu17' target='blank'><img src={map} style={{width:"15%"}}></img></a>
</li>
<li>61, Persiaran Raja Perempuan Mazwin Selatan<br></br>Desa Rishah Indah<br></br>30100 Ipoh, Perak </li>
        </ul>
            

            
        <ul className='text-center fw-light facialUs' style={{listStyleType:"none",marginBottom:'0'}}>
            <li className='footerBreak'>
                <p className='breakLine'></p>
                <h2 className='FooterTitle'>Opening Hours</h2>
                
            </li>

           
            <li style={{marginTop:"10%"}}>Tuesday to Sunday</li>
            <li>11:00am - 7:00pm</li>
        </ul>

    
<ul className='text-center fw-light facialUs' style={{listStyleType:"none",marginBottom:'0'}}>
            <li className='footerBreak'>
                <span className='breakLine'></span>
                <h1 className='FooterTitle'>Important Link</h1>
                
            </li>

            <li style={{marginTop:"10%"}} onClick={handleShows}>Privacy Policy</li>
            <li onClick={handleShow}>Terms and Condition</li> 
            
            <p > &#169; 2024 <i style={{fontSize:'medium'}}> SUVARNAS BRIDAL AND HAIRSTYLE</i></p>
            
            <br></br>
            <p>WEB DESIGN BY <br></br><b>
                <a href='https://www.instagram.com/kumanan7265?igsh=MTBmOGNxeXQ3ZGJoMA=='style={{textDecoration:"none"}}>KUMANAN SUBRAMANIAM</a>
                </b></p>

            </ul>

            </div>

            <div className='mt-3 d-none d-md-flex'>


            <div className="footerLogo text-center rounded-start-5 facialUs" style={{ paddingTop:'1%'}}>

            <img src={navbarImg} style={{width:"50%"}}></img>
<ul className='text-center fw-light facialUs' style={{listStyleType:"none",marginBottom:'0',marginTop:"5%"}}>
            <li><a href='https://waze.com/ul/hw0zf56gnk' target='blank'><img src={waze} style={{width:"15%",marginRight:"8%"}}></img></a>
<a href='https://maps.app.goo.gl/FqgRjg2sLB3Canu17' target='blank'><img src={map} style={{width:"15%"}}></img></a>
</li>
<li>61, Persiaran Raja Perempuan Mazwin Selatan<br></br>Desa Rishah Indah<br></br>30100 Ipoh, Perak </li>
<li className='footerBreak'>
                
                
            </li>

           
            {/* <li style={{marginTop:"5%"}}>Tuesday to Sunday</li>
            <li>11:00am - 7:00pm</li> */}
</ul>
        </div>
        


        <ul className='text-center fw-light facialUs' style={{listStyleType:"none", marginBottom:'0',fontSize:"x-large", paddingTop:'1%'}}>
            <li className='footerBreak'>
                {/* <p className='breakLine'></p> */}
                {/* <h1 className='FooterTitle'>Contact Us</h1> */}
                
            </li>

            <li style={{marginTop:"10%"}}>< a href='tel:+60165027265' target='blank'><img src={call}style={{width:"15%"}}></img></a>
</li>
                    <li style={{marginBottom:"5%"}}>(016)5027265</li>
                    <li>< a href='mailto:suvarna.sdnbhd@gmail.com' target='blank'><img src={mail} style={{width:"15%"}}></img></a>
</li>
<li style={{marginBottom:"5%"}}> suvarna.sdnbhd@gmail.com</li>
<li>< a href='https://wa.me/165027265' target='blank'><img src={whatsapp} style={{width:"15%"}}></img></a>
</li>
<li style={{marginBottom:"5%"}}>https://wa.me/165027265</li>

        </ul>
            

        

    
<ul className='text-center fw-light facialUs rounded-end-5' style={{listStyleType:"none",marginBottom:'0',fontSize:"x-large", paddingTop:'0'}}>
            {/* <li className='footerBreak'>
                <span className='breakLine'></span>
                <h1 className='FooterTitle'>Important Link</h1>
                
            </li> */}

            <li style={{marginTop:"10%", cursor:'pointer'}} onClick={handleShows}>Privacy Policy</li>
            <li onClick={handleShow} style={{cursor:'pointer'}}>Terms and Condition</li> 
            
            <p> &#169; 2024 SUVARNAS BRIDAL AND HAIRSTYLE</p>
            
            <br></br>
            <p>WEB DESIGN BY <br></br><b>
                <a href='https://www.instagram.com/kumanan7265?igsh=MTBmOGNxeXQ3ZGJoMA=='style={{textDecoration:"none"}}>KUMANAN SUBRAMANIAM</a>
                </b></p>

            </ul>

            </div>

                

            
        

        

            <Modal show={shows} onHide={handleCloses}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-start facialUs'>
        Suvarnas Bridal and HairStyle is committed to processing personal data including sensitive personal data in line with all applicable privacy and data protection laws in Malaysia. All collection and use of customer personal data are governed by this Privacy Policy.
<br></br>
<br></br>
This Privacy Policy explains how information is collected, used and disclosed by us when you use our website. It does not apply to any third-party websites, services or applications that you may access through our services.
<br></br>
<br></br>
All references to “you” or “your” and “customers” includes the past, current and future customers of Suvarnas Bridal and HairStyle.
<br></br>
<br></br>
<ol>
    <li>We collect certain information that you provide to us when you use our services, such as when you create an account or submit recommendations. This may include your name, email, contact number and location.</li>
    <li>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but not limited to:
<br></br>
<b>
Email address<br></br>
First name and last name<br></br>
Contact number<br></br>
Social Media account name<br></br>
Address, State, Postcode, & City</b></li>

<li>We may also collect information on how the Service is accessed and used ("Usage Data"). This usage data may include information such as your computer's Internet Protocol address (eg- IP address) browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data</li>
<li>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags and scripts to collect and track information and to improve and analyze our Service. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
<br></br>
Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.</li>
<li>Unless otherwise specified by us at the time the Personal Data is collected, you will be required to supply us with the Personal Data requested in order for you to use our website and/or to receive our products and/or services. If you fail to supply us with the required Personal Data or if the Personal Data is insufficient or not satisfactory to us, we may not be able to process your application and/or provide you with our services and/or products.
<br></br>
When you sign up for our products and services or request for any information from us to enter into a commercial transaction, you consent to us collecting and processing your Personal Data. We will use, process, record, hold, store, share and disclose Personal Data with your consent.
<br></br>
Continued use and visits to our websites, use of our products and/or services shall amount to your consent for the processing of your Personal Data by us.
<br></br>
If you are under the age of 18, you are required to obtain the consent of your parents or legal guardian before using our website, products and/or services.</li>

<li>We use the information we collect for the purposes described below:
    <ul>
        <li>to provide our services and related services or information you request, and to process and complete any transactions; to respond to your emails, submissions, questions, comments, requests, and complaints and provide customer service;</li>
        <li>to personalise and improve our services and your experiences when you use our services;</li>
        <li>to increase the functionality and user friendliness of our services;</li>
        <li>to monitor and analyse usage and trends of our services and our sites,</li>
        <li>to send you confirmations, updates, security alerts, support and administrative messages and otherwise facilitate your use of, and our administration and operation of, our services;</li>
        <li>to send you recommendations and advertisements tailored to your interests;</li>
        <li>for any other purpose for which the information was collected.</li>
    </ul>

</li>

<li>We will not share the Personal Data  we have collected from you, except as described below:</li>
<ul>
    <li>when you give us your consent to do so, including if we notify you that the information you provide will be shared in a particular manner and you agreed to provide such information;</li>
    <li>when you explicitly allow Suvarnas Bridal and HairStyle to see your contact details to be able to contact you directly in regards to a request that you have submitted;</li>
    <li>at certain social network services and sites, if you allow such sharing;</li>
    <li>with service professionals who are working with us in connection with the operation of our services and our sites (these service professionals may have access to your personal information but only to the extent necessary to perform services on our behalf and are obligated not to disclose that information or use it for any other purposes);</li>
    <li>we may share aggregated information and non-identifiable information that does with third parties for industry analysis, demographic profiling, research, analysis and other similar purposes;</li>
    <li>when we believe that we are legally authorised or required to do so or that doing so is reasonably necessary or appropriate to comply with the law or legal processes or respond to lawful requests or legal authorities, including responding to subpoenas, warrants or court orders;</li>
    <li>to enforce or apply this Privacy Policy, our Terms of Use or our other policies or agreements;</li>
    <li>We may share aggregated or anonymous information (about you but does not identify you) with third parties:</li>
    <li>for usage analytics to help us better understand who is using our services and how;</li>
    <li>to improve the relevancy of the ads you see on our services and to tailor them to your interests.</li>

</ul>

<li>If any part of our websites linked you to other third party websites, any information you provide to these Third Party Websites/Services is provided directly to the owner of that website or service and is subject to their own terms and policies, including privacy and data gathering practices. Our Privacy Policy does not apply to these Third Party Services. We are not responsible for the privacy and security practices and policies of any Third Party Services.</li>
<li>We employ administrative, physical and electronic measures designed to protect your information from unauthorized access, however, despite these efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed against any interception or other type of misuse. In the event that your Personal Data is compromised as a result of a breach of security, we will promptly notify you  as required by applicable law.</li>
<li>You may withdraw your consent for us to contact you, for the continue collection, usage or disclosure of your information, at anytime, by contracting us at suvarna.sdnbhd@gmail.com with a request that we delete your Personal Data  from our database. We will use commercially reasonable efforts to honor your request; however, we may retain an archived copy of your records as required by law or for other legitimate business purposes.</li>


</ol>

If you have any questions in relation this this Privacy Notice, please contact us via email:
<br></br> suvarna.sdnbhd@gmail.com

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloses}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>

        {/* </div> */}
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Condition</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-start facialUs'>The following Terms of Services (“Terms”) between you (“you” or “your”) and Suvarnas Bridal and HairStyle (“we”, “our”, “us” or “Suvarnas Bridal and HairStyle”) describes the terms and conditions on which you may choose and place an order for any of the services offered on our website,
        <br></br>
        <br></br>
All  the Terms herein contained and the terms and condition as may be stipulated in our invoice to you shall form the terms and conditions of  a legally binding contract between you and Suvarnas Bridal and HairStyle. By accepting these Terms you represent that you are an individual of legal age to be bound by the terms hereof or, if you are not, that you have obtained parental or guardian consent to be bound by these Terms.
<br></br>
<br></br>

<ul>
<li>
You consent to receive notices from us, by email, text messages, calls and through social medias including WhatsApp, telegram or messenger for the purposes of notifying you about the status of your order, sending you reminders and providing other information.
We may contact you by telephone calls or text messages, including automatic telephone dialing systems, at the telephone numbers provided by you.
You may opt out of receiving notices from us by contacting us at suvarna.sdnbhd@gmail.com with a request to opt out of receiving notices from us. You acknowledge that opting out of receiving notices may impact your use of the Services.
</li> 
<br></br>

<li>
Upon selection and finalization of the desired make-up package, a Booking Fee (50% of the total package cost) is required to secure the booking time and date. Payment of the Booking Fee is to be made via online bank transfers only. Proof of payment/transfer is to be sent to Suvarnas Bridal and HairStyle for verification and confirmation of booking time and date. Bookings are not deemed secured unless the Booking Fee has been paid.
Upon payment of the Booking Fee, we are assigned and dedicated to your booking, hence we will not be available to take any other bookings for that time slot. Upon notification and confirmation of us and time slot selected, it is deemed final and conclusive. As such, any cancellation or modification to the package or booking time and date will result in the forfeiture of the Booking Fee.
Full payment is required on the day of all appointments. Strictly no deferment of balance payment. 
</li>

<br></br>
Transportation charges will be chargeable for venues further than 10km from Suvarnas Bridal and HairStyle’s saloon in Ipoh.
Prices of all items and packages are determined and finalised at the time of booking and confirmation. Any add-on (package, items, services, etc) will be charged with at the latest price rate.
Pricelist are updated yearly and is subject to change without notice.
<br></br>
<li>
Any requests to modify the booked package or changes to the booking time due to unforeseen circumstance, has to be communicated to Suvarnas Bridal and HairStyle within 48 hours after confirmation of booking. Suvarnas Bridal and HairStyle will accommodate a rescheduling of the booking without any penalty. Any request for changes that is received after 48 hours of the initial confirmation of booking will not be entertained and will result in the forfeiture of the Booking Fee. Any rescheduling will be subjected to new Booking Fees and subjected to availability.
We reserve the right to terminate your booking at any time for any reason including the day of the event if it is due to client(s)/representatives’ behaviour, actions or inactions that causes distress to the MUA. Aggressive, rude or obnoxious behaviour will not be tolerated by the MUA, any assistants or sub-contractors.
</li>
<br></br>
<li>
Any changes/rescheduling arising from COVID-19 lock-down restrictions that affect bookings for Bridal/Wedding Packages (ROM/Engagements/Actual Day, etc) will be accommodated on a best effort basis and subjected to availability. Any other reason for changes and rescheduling of appointments and time slot are not accepted unless expressly mentioned.  Do take note that changes to new dates will also be subject to our availability. Booking Fees or any other payments made for the selected packages (“Deposits”) WILL BE forfeited in the event we are not available for your newly amended dates.
For Two or Three session Bridal Packages, Booking Fees collected are prorated over the number of sessions. Should there be a reduction in the number of sessions required, the Booking Fees are recalculated based on the new Bridal Package chosen. The Booking Fees paid for the cancelled/unutilized session will be forfeited. Any shortfall in Booking Fees for the new sessions will need to be settled at the time of confirmation of change.
A deposit of up to MYR200 is required for each bridal hair accessory loaned. This deposit will be refunded upon receiving of the loaned accessory/ies in its original condition within 3 days after the wedding event. All items are to be returned to the Suvarnas Bridal and HairStyle saloon in Ipoh. Deposits will be automatically forfeited for failure to comply with the above conditions, or if items are damaged or lost. 
If the venue of the appointment is in a hotel, please arrange for the MUA’s complimentary parking or provide reimburse for the cost of parking for the duration of the appointment.
If a second march-in or stand-by for touch-ups is required, kindly provide meals for us.
</li>
</ul>

<br></br>
Thank you for agreeing to our terms & conditions and we look forward to seeing you soon.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>



        </>
    )
}

export default Footer