import fbDt1 from './fbdt1.png'
import fbDt2 from './fbdt2.png'
import fbDt3 from './fbdt3.png'
import fbDt4 from './fbdt4.png'
import fbDt5 from './fbdt5.png'
import fbDt6 from './fbdt6.png'
import fbDt7 from './fbdt7.png'
import fbDt8 from './fbdt8.png'
import fbDt9 from './fbdt9.png'
import fbDt10 from './fbdt10.png'



const reviewDt =   

         [

            {"id":1,  
            "wsup": fbDt1, 
            "feed": "wow akka lovely, seriosly damn nice. Thank you so much akka"},
            {"id":2,
                "name": "Sudar", 
            "wsup": fbDt2, 
            "feed": "All said that my makeup was so beautiful n I look nice.. thank you for your makeup"},
            {"id":3,
        "wsup":fbDt3, 
        "feed": "Akka Bridal makeup compliment too good from everyone "},
        {"id": 4,
    "wsup": fbDt4,
"feed": "Your touch made me glow yesterday, everyone loves ur work. Great job sis. Thanks again"},
        {"id":5,
    "wsup":fbDt5,
    "feed":"Thanks alot for the beautiful makeup. And I felt so much comfortable with you"
},
{"id":6,
"wsup":fbDt6,
"feed":"criuzly u made my day aka.. ur makeover was criuzly damn nice aka. They even told  i look so natural even after makeup",
},
{"id":7,
"wsup":fbDt7,
"feed": "Thank you so much for dolling me on time akka. you' ve magic on on ur fingers. It was so pleasant to know you akka"},
{"id": 8,
"wsup":fbDt8,
"feed": "Thank you from the bottom of my heart for making me to look great beyond my expection. Your handwork was a magic"},
{"id":9,
"wsup":fbDt9,
"feed":"Nite make over remove panne manase varle sis"
} ,
{"id":10,
"wsup":fbDt10,
"feed":"It was soo amazing sis, My parents n family members all say reallluy nice sis"}          
        ]

        export default reviewDt