import React from 'react'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import Navibar from './Navibar'
import FbPixel from './FbPixel'
import Footer from './Footer'
import ms3 from './ms3.jpg'
import ms2 from './ms2.jpg'
import ms1 from './ms1.jpg'
import msm1 from "./msm1.jpg"
import ms1dt from './ms1dt.jpg'
import msdt1 from './msdt1.jpg'
import msdt2 from './msdt2.jpg'
import msdt3 from './msdt3.jpg'
import './App.css';
import WeddingMakeup from './WeddingMakeup';

import { useNavigate } from "react-router-dom";
import GoToTop from './GoToTop'

const MakeupSelection = () => {
  const navigate = useNavigate();
  const weddingPage = () => {
    navigate('/WeddingMakeup');
  };

  const engagementPage = () => {
    navigate('/EngagementMakeup');
  };

  const dinnerPage = () => {
    navigate('/DinnerMakeup');
  };

  useEffect (() => {
    ReactGA.send("pageview");
  }, []);

  return (
    <>

    <FbPixel />

<Navibar />
<div className=" card text-bg-dark rounded-bottom-5 d-md-none " >
  <img src={msm1}className="card-img-top" alt="Suvarnas Bridal"></img>
  {/* <img src={fc11} className="facialTitle" alt="Suvarnas Bridal" data-aos="fade-right" data-aos-duration="2000"></img> */}
  
  <div className="text-center fw-light facialUs rounded-bottom-5">
<h3>  Let's make your once in a lifetime moment very special. 
      <br></br>
      <br></br>
      I want to help you create memories you will cherish, looking your most radiant.
      <br></br>
      <br></br>
      My goal is to illuminate and enhance the natural beauty of you and to help create the look you are dreaming of.
</h3>
 
</div>
</div>

<div className=" card text-bg-dark rounded-bottom-5 d-none d-md-block " >
  <img src={ms1dt}className="card-img-top" alt="Suvarnas Bridal"></img>
  {/* <img src={fc11} className="facialTitle" alt="Suvarnas Bridal" data-aos="fade-right" data-aos-duration="2000"></img> */}
  
  <div className="text-center fw-light facialUs rounded-bottom-5">
<h3>  Let's make your once in a lifetime moment very special. 
      
      I want to help you create memories you will cherish, looking your most radiant.
      
      My goal is to illuminate and enhance the natural beauty of you and to help create the look you are dreaming of.
</h3>
 
</div>
</div>

    {/* <div className='container'>
    <h3 className="text-center fw-light msTitle" >
      Let's make your once in a lifetime moment very special. 
      <br></br>
      <br></br>
      I want to help you create memories you will cherish, looking your most radiant.
      <br></br>
      <br></br>
      My goal is to illuminate and enhance the natural beauty of you and to help create the look you are dreaming of.
    </h3>
    </div> */}
    

    <div className='container'>
    <br></br>

<h1 className='title'> Choose Your Makeup</h1>

<br></br>
    </div>


      <div className="card text-bg-dark rounded-5 ">
  <img src={ms1} className="card-img rounded-5  d-md-none" alt="Suvarnas Bridal" onClick={weddingPage}></img>
  <img src={msdt2} className="card-img rounded-5 d-none d-md-block" alt="Suvarnas Bridal" onClick={weddingPage}></img>
  
</div>
<br></br>
<div className="card text-bg-dark rounded-5 ">
  <img src={ms2} className="card-img  rounded-5 d-md-none" alt="Suvarnas Bridal" onClick={engagementPage}></img>
  <img src={msdt3} className="card-img rounded-5 d-none d-md-block" alt="Suvarnas Bridal" onClick={engagementPage}></img>
  
</div>
<br></br>
<div className="card text-bg-dark rounded-5">
  <img src={ms3} className="card-img rounded-5 d-md-none" alt="Suvarnas Bridal" onClick={dinnerPage}></img>
  <img src={msdt1} className="card-img rounded-5 d-none d-md-block" alt="Suvarnas Bridal" onClick={dinnerPage}></img>
  
</div>

<GoToTop />
<Footer />

    </>
  );
};


export default MakeupSelection