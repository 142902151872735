import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import FbPixel from './FbPixel';
import GoToTop from './GoToTop';
import {useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ff1 from './ff1.png'
import ff2 from './ff2.png'
import ff3 from './ff3.png'
import ff4 from './ff4.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navibar from './Navibar';
import Footer from './Footer';
import fcb4 from './fcb4.jpg'

const FruitFacial = () => {
  AOS.init();
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [facial, setFacial] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [enquiry, setEnquiry] = useState("");

 

 

  const wsup = "https://wa.me/165027265?text=" 
  + "Name : "+ name + "%0a"
  + "Phone Number : " + phone + "%0a"
  + "Facial Type : "+ facial + "%0a"
  + "Preferred Date : "+ date + "%0a"
  + "Preferred Time : " + time + "%0a"
  + "Enquiries : " + enquiry + "%0a%0a";


  const message = (e) => {
      e.preventDefault ();
      window.open(wsup, '_blank');
  }

  const navigate = useNavigate();

        const navigateToFacial = () => {
          navigate('/Facial');
        };

        useEffect (() => {
          ReactGA.send("pageview");
        }, []);
    return (
        <>

        <FbPixel />

<Navibar />
<div className='facialBody'>
<div className="card text-bg-dark facialBanner">

        <img src={fcb4} className="card-img " alt="Suvarnas Bridal"></img>
       
</div>
<div className='container rounded-bottom-5' style={{padding:"8%",backgroundColor:"#0f0e0e"}}>
<div className="text-center fw-light whyUs">
        <h3>
            <b style={{color:"#82A136"}}>WHAT YOU CAN EXPECT? </b>
            </h3>
            </div>



<div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>☛</p>
      </div>
      <div className='stepsInfo'><h5>Suitable for all type of skins.</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar'  style={{backgroundColor:"#82A136"}}>☛</p>
      </div>
      <div className='stepsInfo'><h5> Deep cleansing of the skin.
         Reduces pigmentation and blotchiness on the face while improving skin tone.</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>☛</p>
      </div>
      <div className='stepsInfo'><h5>Combat free radicals while reducing signs of aging to show on the skin.</h5></div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>☛</p>
      </div>
      <div className='stepsInfo'><h5>Exfoliate the skin, eliminating all dead cells gives a smooth skin by adding natural shine.</h5></div>
      </div>

     
      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>☛</p>
      </div>
      <div className='stepsInfo'><h5>Provides beautiful face by maintaining the skin ph level and revitalizes the skin</h5>
</div>
      </div>

      <div className='steps2' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>☛</p>
      </div>
      <div className='stepsInfo'><h5>Nourish the skin with essential vitamins and it rejuvenates the skin by giving a radiant glow.</h5>
</div>
      </div>


      </div>
        

      <div className='facialCard'>
        <div className="card text-bg-dark rounded-top-5 facialIng" 
        data-aos="fade-up" data-aos-duration="1500">
        <div className='card-header text-center fw-light'  ><h3>
            <b style={{color:"#82A136"}}><b>WHAT ARE THE MAIN INGREDIENTS INSIDE?</b> </b>
            </h3></div>
    <img src={ff1} className="card-img-top" alt="Suvarnas Bridal"  />
    <div className="card-body">
      <h2 className="card-title text-center" style={{color:"#82A136"}}><b>Ginger</b></h2>
      <h5 className="card-text text-center">
      Extract warms to minimize skin roughness, promoting soft, 
      smooth skin to the touch Because of its antioxidant characteristics, its also assists to reduce dark spots.
      </h5>
     
    </div>
  </div>
  <div className="card text-bg-dark facialIng" data-aos="fade-up" data-aos-duration="1500">
    <img src={ff2} className="card-img-top" alt="Suvarnas Bridal" />
    <div className="card-body">
      <h2 className="card-title text-center" style={{color:"#82A136"}}><b>Orange Peel Extracts</b></h2>
      <h5 className="card-text text-center" >
      Efficient exfoliating agent that assists in the elimination of blackheads and the unclogging of skin pores.
      </h5>
     
    </div>
  </div>

  <div className="card text-bg-dark facialIng" data-aos="fade-up" data-aos-duration="1500">
    <img src={ff3} className="card-img-top" alt="Suvarnas Bridal" />
    <div className="card-body">
      <h2 className="card-title text-center" style={{color:"#82A136"}}><b>Papaya</b></h2>
      <h5 className="card-text text-center">
      Stimulates skin healing and increases collagen secretion and assists in the correction of skin pigmentation &
       discoloration as a result, it also gives the deep hydration & nourishment to skin.
      </h5>
     
    </div>
  </div>

  <div className="card text-bg-dark rounded-bottom-5 facialIng" data-aos="fade-up" data-aos-duration="1500">
    <img src={ff4} className="card-img-top" alt="Suvarnas Bridal" />
    <div className="card-body">
      <h2 className="card-title text-center" style={{color:"#82A136"}}><b>Mango</b></h2>
      <h5 className="card-text text-center">
      Reduce wrinkles and blemishes while boost collagen production and protect skin from free radical. 
      Also its high in antioxidants which help combat the effect of sun damages and pollution.
      </h5>
     
    </div>
  </div>

  
  
</div>

<br></br>
<div className='container rounded-5' style={{padding:"8%",backgroundColor:"#0f0e0e"}}>
<div className="text-center fw-light whyUs">
        <h3>
            <b style={{color:"#82A136"}}>WHAT ARE THE PROCEDURES OF FRUIT FACIAL? </b>
            </h3>
            </div>



<div className='steps'data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber' >
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>1</p>
      </div>
      <div className='stepsInfo'><h5>Thorough cleansing to remove makeup, dirts and impurities from deep within the pores.</h5></div>
      </div>

      <div className='steps' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>2</p>
      </div>
      <div className='stepsInfo'><h5>Exfoliate to remove dead skin cells. This helps promotes cell turnover.</h5></div>
      </div>

      <div className='steps' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>3</p>
      </div>
      <div className='stepsInfo'><h5>Relaxing facial massage to stimulate blood circulation, and promote lymphatic drainage.</h5></div>
      </div>

      <div className='steps' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>4</p>
      </div>
      <div className='stepsInfo'><h5>Apply customized mask which are infused with potent ingredients 
      to address specific skincare needs.</h5></div>
      </div>

     
      <div className='steps' data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>5</p>
      </div>
      <div className='stepsInfo'><h5>Apply High-concentration serums packed with active ingredients
      to shine, even texture & supple skin.</h5>
</div>
      </div>

      <div className='steps'data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      <p className='verticalLine'></p>
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>6</p>
      </div>
      <div className='stepsInfo'><h5>To seal in all the goodness 
      moisturizers rich in hydrating ingredients are applied.</h5>
</div>
      </div>

      <div className='steps'data-aos="fade-up" data-aos-duration="1500">
      <div className='stepsNumber'>
      {/* <p className='verticalLine'></p> */}
      <p className='circleBar' style={{backgroundColor:"#82A136"}}>☛</p>
      </div>
      <div className='stepsInfo'><h5>Estimated total time: 90 minutes</h5>
</div>
      </div>

      </div>
      
      <div className="container d-grid gap-2 "> 
    <h3 className='text-center facialPrice' data-aos="fade-up" data-aos-duration="1500">RM 55 only</h3>
  <button className="btn btn-primary border-0 facialPrice" type="button" style={{backgroundColor:"#82A136"}} data-aos="zoom-in" data-aos-duration="1500" onClick={handleShow}>Book Now</button>
  <button className="btn btn-primary border-0 facialPrice" type="button" style={{backgroundColor:"#82A136",fontSize:"x-large"}} data-aos="zoom-in" data-aos-duration="1500" onClick={navigateToFacial}>Back To Selection </button>
  
</div>

< GoToTop />
<Footer />
</div>

<Modal style={{fontFamily:"Cormorant Garamond", color:"antiquewhite"}} show={show} onHide={handleClose}>
        <Modal.Header style={{backgroundColor:"#0f0e0ebd"}} closeButton >
          <Modal.Title> Facial Booking Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className=' formStyle'>

        <Form  onSubmit={message}> 
        
        <Form.Group className="mb-3" controlId="formGridName">
          <Form.Label>Name</Form.Label>
          <Form.Control required resize="lg" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridPhone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control size="lg" type='number' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridSelect">
        <Form.Label>Facial Type</Form.Label>
        <Form.Select size='lg' required value={facial} onChange={(e) => setFacial(e.target.value)} aria-label="Default select example">
    <option>Select Facial Type</option>
    <option value="Gold Facial">Gold Facial</option>
    <option value="Chcocolate Facial">Chcocolate Facial</option>
    <option value="Papaya Facial">Papaya Facial</option>
    <option value="Fruit Facial">Fruit Facial</option>
    <option value="Pearl Facial">Pearl Facial</option>
    <option value="vitamin C Facial">vitamin C Facial</option>
  </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridDate">
        <Form.Label>Preferred Date</Form.Label>
        <Form.Control size="lg" type='date' required placeholder="dd-mm-yyyy" value={date} onChange={(e) => setDate(e.target.value)}
  min="2024-03-01" max="2025-12-31" />
      </Form.Group>
     

      <Form.Group className="mb-3" controlId="formGridTime">
        <Form.Label>Preferred Time</Form.Label>
        <Form.Control size="lg" required type='time' placeholder="hh:mm " value={time} onChange={(e) => setTime(e.target.value)} />
      </Form.Group>

     


      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Enquiries</Form.Label>
        <Form.Control size="lg" as="textarea" placeholder='Message' value={enquiry} onChange={(e) => setEnquiry(e.target.value)} />
        </Form.Group>
  
        <Button  className='formButton' variant="secondary" type="submit">
          Submit
        </Button>
      </Form>

      <div className='formStyle' style={{backgroundColor:"#0f0e0ebd"}}>

      <i>Please take note: You need WhatsApp to send this form to us. </i>
      </div>

       

        </Modal.Body>
        <Modal.Footer style={{backgroundColor:"#0f0e0ebd"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>

</>
    )
}

export default FruitFacial