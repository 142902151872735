import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Home from "./Home";
import ReactGA from 'react-ga4'
import MakeupSelection from './MakeupSelection';
import reportWebVitals from './reportWebVitals';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WeddingMakeup from './WeddingMakeup';
import EngagementMakeup from './EngagementMakeup';
import DinnerMakeup from './DinnerMakeup';
import Gallery from './Gallery';
import Facial from './Facial';
import EyebrowEmbroidery from './EyebrowEmbroidery';
import Pleating from './Pleating';
import Course from './Course';
import GoldFacial from './GoldFacial';
import PapayaFacial from './PapayaFacial';
import FruitFacial from './FruitFacial';
import PearlFacial from './PearlFacial';
import ChocolateFacial from './ChocolateFacial';
import VitaminCFacial from './VitaminCFacial';
import EnquiryForm from './EnquiryForm';
import PageNotFound from './PageNotFound';

// import Portfolio from './Portfolio';

export default function App() {

  ReactGA.initialize('G-HEV9HKVJV7');
  return (
    <BrowserRouter>
    
    
        <Routes>

        <Route path="/" element={<Home />} />
        
          <Route path="/MakeupSelection" element={<MakeupSelection />} />
          <Route path='/WeddingMakeup' element={<WeddingMakeup />} />
          <Route path='/EngagementMakeup' element={<EngagementMakeup />} />
          <Route path='/DinnerMakeup' element={<DinnerMakeup />} />
          <Route path='/Gallery' element={<Gallery />} />
          <Route path='/Facial' element={<Facial />} />
          <Route path='/EyebrowEmbroidery' element={<EyebrowEmbroidery />} />
          <Route path='/Pleating' element={<Pleating />} />
          <Route path='/Course' element={<Course />} /> 
          <Route path='/GoldFacial' element={<GoldFacial />} />
          <Route path='/PapayaFacial' element={<PapayaFacial />} />
          <Route path='/ChocolateFacial' element={<ChocolateFacial />} />
          <Route path='/PearlFacial' element={<PearlFacial />} />
          <Route path='/FruitFacial' element={<FruitFacial />} />
          <Route path='/VitaminCFacial' element={<VitaminCFacial />} />
          <Route path='/EnquiryForm' element={<EnquiryForm />} />
          <Route path= '*' element={<PageNotFound />} />
          
        </Routes>
      
        </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
