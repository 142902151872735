import React from 'react'
import Button from 'react-bootstrap/Button';
import {useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4'
import FbPixel from './FbPixel';
import GoToTop from './GoToTop';
import Navibar from './Navibar';
import Accordion from 'react-bootstrap/Accordion';
import Footer from './Footer';
import Modal from 'react-bootstrap/Modal';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emmb from './emm1.jpg'
import emdt from './emdt.jpg'
import em1mb from './em1mb.jpg'
import em2mb from './em2mb.jpg'
import em1dt from './em1dt.jpg'
import em2dt from './em2dt.jpg'


const EngagementMakeup = () => {

  AOS.init();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [makeup, setMakeup] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [location, setLocation] = useState("");
  const [travel, setTravel] = useState("");
  const [enquiry, setEnquiry] = useState("");

 

 

  const wsup = "https://wa.me/165027265?text=" 
  + "Name : "+ name + "%0a"
  + "Phone Number : " + phone + "%0a"
  + "MakeUp Type : "+ makeup + "%0a"
  + "Event Date : "+ date + "%0a"
  + "Time To Be Ready : " + time + "%0a"
  + "Location : " + location + "%0a"
  + "Travel : " + travel + "%0a"
  + "Enquiries : " + enquiry + "%0a%0a";


  const message = (e) => {
      e.preventDefault ();
      window.open(wsup, '_blank');
  }

  const navigate = useNavigate();

      const navigateToGallery = () => {
        navigate('/Gallery');
      };

      useEffect (() => {
        ReactGA.send("pageview");
      }, []);
  
  return (
    <>

<FbPixel />
< Navibar />

<div className=" card text-bg-dark rounded-5 d-md-none" >
  <img src={emmb} className="card-img-top" alt="Suvarnas Bridal"></img>
  <div className="text-center fw-light facialUs rounded-bottom-5" >
  
     <h4>The once in a lifetime moment is here. 
      I'm dedicated to create the perfect bridal look that emphasize your 
      natural beauty and enhances your unique style. My ultimate target is for you to feel confident and comfortable
      on your Engagement day and that your best features are expectedly defined and enhanced.</h4>
    
    
       </div>
 
</div>



<div className=" card text border-0 d-none d-md-block" style={{backgroundColor:"black"}}>
  <img src={emdt} className="card-img-top" alt="Suvarnas Bridal"></img>
  <div className="container text-center fw-light p-5 whyUsDt"   >
  
     <h5>The once in a lifetime moment is here. 
      I'm dedicated to create the perfect bridal look that emphasize your 
      natural beauty and enhances your unique style. My ultimate target is for you to feel confident and comfortable
      on your Engagement day and that your best features are expectedly defined and enhanced.</h5>
    
    
       </div>
       </div>



<h1 className='d-none d-md-block title'> why choose us</h1>



<div className=" card text-bg-dark rounded-5 d-md-none pwuBg"  >
  <img src={em2mb} className="card-img-top rounded-top-5 " alt="Suvarnas Bridal"></img>
  <div className="text-center fw-light whyUs rounded-bottom-5" data-aos="fade-up" data-aos-duration="1500">
    <h2 data-aos="fade-right" data-aos-duration="1500">Personalized Consultation:</h2> 
    We will work closely with you to understand your ideas, preferences and concerns. We offer a tailored consultation
    to ensure that your makeup is perfect for your Engagement day.
    <hr></hr>
      <h2 data-aos="fade-right" data-aos-duration="1500">Customized Looks:</h2>
    Every Bride is unique and so is her vision for her special day. We offer 
    customized bridal makeup look that suits your personality, style and the theme of your Engagement.
    <hr></hr>
     <h2 data-aos="fade-right" data-aos-duration="1500">On-Location Services:</h2>
      We offer on-location services, so you can get ready in the comfort of your own home or venue.
      <hr></hr>
       <h2 data-aos="fade-right" data-aos-duration="1500">Flawless Hairstyling:</h2>
       We are experts in creating beautiful, long-lasting bridal hairstyles, whether you desire an updo, down hairstyle, braids, 
       or any other style. We will ensure your hair looks perfect throught out the day.
       <hr></hr>
       <h2 data-aos="fade-right" data-aos-duration="1500">Touch-up Services:</h2>
       We offer touch-up services on second saree changing to ensure you keep looking stunning in person and in photographs.
       </div>
</div>

<div className='container'>

<div className="card text d-none d-md-block wpCard">
  <div className="row g-0">
    
    <div className="col-md-6">
   
      <div className="card-body pt-0">
      <div className="text-center fw-light whyUsDt" data-aos="fade-up" data-aos-duration="1500" >
    <h3 data-aos="fade-right" data-aos-duration="1500">Personalized Consultation:</h3> 
    We will work closely with you to understand your ideas, preferences and concerns. We offer a tailored consultation
    to ensure that your makeup is perfect for your Engagement day.
    <hr></hr>
      <h3 data-aos="fade-right" data-aos-duration="1500">Customized Looks:</h3>
    Every Bride is unique and so is her vision for her special day. We offer 
    customized bridal makeup look that suits your personality, style and the theme of your Engagement.
    <hr></hr>
     <h3 data-aos="fade-right" data-aos-duration="1500">On-Location Services:</h3>
      We offer on-location services, so you can get ready in the comfort of your own home or venue.
      <hr></hr>
       <h3 data-aos="fade-right" data-aos-duration="1500">Flawless Hairstyling:</h3>
       We are experts in creating beautiful, long-lasting bridal hairstyles, whether you desire an updo, down hairstyle, braids, 
       or any other style. We will ensure your hair looks perfect throught out the day.
       <hr></hr>
       <h3 data-aos="fade-right" data-aos-duration="1500">Touch-up Services:</h3>
       We offer touch-up services on second saree changing to ensure you keep looking stunning in person and in photographs.
       </div>
        
        
      </div>
       
    </div>
    <div className="col-md-6">
      <img src={em2dt} className="img-fluid rounded-end wpCard" alt="Suvarnas Bridal" style={{width:"100%"}}></img>
    </div>
  </div>
</div>
</div>





<h1 className='d-none d-md-block title'> Engagement Package</h1>



<div className=" card text-bg-dark rounded-5 d-md-none" >
  <img src={em1mb} className="card-img-top rounded-top-5" alt="Suvarnas Bridal"></img>
  {/* <div className="card-img-overlay"> */}
    {/* <br></br> */}
    
  
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500"> Bridal HD Make-up</div>
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500">Any contemporary or traditional bridal hairstyle</div>
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500">Saree draping for saree / lehenga <br></br> (include 2nd saree change)</div>
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500">Saree pre-pleating, ironing and <br></br> box folding</div>
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500">Bridal Jewellery / hair accessories <br></br>(2 sets)</div>
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500">Flowers (kondamalai / jadamalai)</div>
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500">Touch up makeup during the 2nd saree changing</div>
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500">Fake eyelashes</div>
    <div className="card-header wMenu" data-aos="fade-right" data-aos-duration="1500" style={{fontSize:"xx-large"}}>RM 1200 only</div>

    <div className='container bookMu'>
        <button type="button"  className="btn btn-dark m-2" style={{fontSize:"larger"}}onClick={handleShow}>BOOK NOW</button>
             
        <button type="button" className="btn btn-dark m-2" style={{fontSize:"larger"}} onClick={navigateToGallery}>VIEW GALLERY</button>
            </div>
  
   
  {/* </div> */}
</div>

<div className='container'>

<div className="card text d-none d-md-block wpCard">
  <div className="row g-0">
    <div className="col-md-6">
      <img src={em1dt} className="img-fluid rounded-start wpCard" alt="Suvarnas Bridal" style={{width:"100%"}}></img>
    </div>
    <div className="col-md-6">
   
      <div className="card-body pb-1">
        
        <p className="card-text wMenuDt" data-aos="fade-right" data-aos-duration="1500">Bridal HD Make-up</p>
        <p className="card-text wMenuDt" data-aos="fade-right" data-aos-duration="1500">Any contemporary or traditional bridal hairstyle</p>
        <p className="card-text wMenuDt" data-aos="fade-right" data-aos-duration="1500">Saree draping for saree / lehenga (include 2nd saree change)</p>
        <p className="card-text wMenuDt" data-aos="fade-right" data-aos-duration="1500">Saree pre-pleating, ironing and box folding</p>
        <p className="card-text wMenuDt" data-aos="fade-right" data-aos-duration="1500">Bridal Jewellery / hair accessories (2 sets)</p>
        <p className="card-text wMenuDt" data-aos="fade-right" data-aos-duration="1500">Flowers (kondamalai / jadamalai)</p>
        <p className="card-text wMenuDt" data-aos="fade-right" data-aos-duration="1500">Touch up makeup during the 2nd saree changing</p>
        <p className="card-text wMenuDt" data-aos="fade-right" data-aos-duration="1500">Fake eyelashes</p>
        <h5 className="card-title wMenuDt" data-aos="fade-right" data-aos-duration="1500" style={{fontSize:"xxx-large"}}>RM 1200</h5>
        
        <div className='container bookMu'>
        <button type="button"  className="btn btn-dark m-2" style={{fontSize:"larger"}}onClick={handleShow}>BOOK NOW</button>
             
        <button type="button" className="btn btn-dark m-2" style={{fontSize:"larger"}} onClick={navigateToGallery}>VIEW GALLERY</button>
            </div>
        
      </div>
    
   
      
    </div>
  </div>
</div>
</div>

<br></br>

<h1 className='title'>F A Q</h1>

<br></br>

<div className='faq'>
<Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header >HOW DO I BOOK YOU FOR MY ENGAGEMENT?</Accordion.Header>
        <Accordion.Body>
          To reserve your session, please contact us via whatsapp. Include your name along with the date and time of your Engagement day. 
          Please allow up to 24 hours for an enquiry response. All our services are subject to availibility.  
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>IS DEPOSIT REQUIRED TO SECURE AN APPOINTMENT?</Accordion.Header>
        <Accordion.Body>
          We require 50% of the total cost of bridal services at the time of booking. No date will be held without a deposit. 
          The remaining balance is due 48 hours prior to Engagement day.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>CAN I CANCEL MY BOOKING?</Accordion.Header>
        <Accordion.Body>
          For cancellation of booking, no refund will be provided as Engagement dates usually fall on auspicious
          day. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3"data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>HOW LONG DOES MAKEUP APPLICATION TAKE?</Accordion.Header>
        <Accordion.Body>
         Bridal Makeup application can take anywhere from 3 to 4 hours, depending on the kind of look you are requesting.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>HOW LONG DOES BRIDAL HD MAKEUP STAYS?</Accordion.Header>
        <Accordion.Body>
         For Bridal HD Makeup, it lasts for 8 hours without fail. You might need little touch ups if you want your makeup to stay longer.
         (Please take note: We will do makeup touch-up on second saree changing)
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>WHAT DOES A TYPICAL MAKEUP APPLICATION LOOK LIKE?</Accordion.Header>
        <Accordion.Body>
        The skin will be prepped for makeup by removing any excess makeup or dirt on the skin. 
        Moisturizer and primer is applied according to your skin type. 
        Eye makeup will be done prior to the foundation to avoid eye shadow "fallout". 
        Brows are filled in followed by contour, bronzer, blush and highlighter. Lashes are then applied following a lip color. 
        The look is completed with setting powder and finishing spray.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>HOW DO I PREPARE FOR MY MAKEUP APPLICATION?</Accordion.Header>
        <Accordion.Body>
        One week prior to your makeup appointment, make sure that you are consistent with your skincare regimen by 
        cleansing and moisturizing, day and night. 
        Drink plenty of water. Use a gentle exfoliator on your skin twice a week. 
        Clean up your brow shape by threading.
         Ask us about scheduling a Facial Treatment for the ultimate Engagement skin prep!
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>DO YOU HAVE TRIAL MAKEUP?</Accordion.Header>
        <Accordion.Body>
       Sorry we don't provide trial makeup service.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>CAN I CHOOSE MY BRIDAL JEWELLERY?</Accordion.Header>
        <Accordion.Body>
       Yes of course. We have variety of Bridal Jewellery for you to choose. We will guide you to select the best 
       that suites your costumes.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>WHEN CAN I SELECT MY BRIDAL JEWELLERY?</Accordion.Header>
        <Accordion.Body>
       Please enquire us for appointment for selecting Bridal Jewellery. Appointment should be atleast 2 weeks before your Engagement day.
       Remember to bring your sarees together on jewellery selection appointment.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>WHEN I SHOULD HANDOVER SAREES FOR PRE-PLEATING?</Accordion.Header>
        <Accordion.Body>
       Please handover to us atleast 2 weeks before your Engagement day. It can be also the same day of 
       Bridal Jewellery selection appointment day.
        </Accordion.Body>
      </Accordion.Item>
      
      <Accordion.Item eventKey="11" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>DO YOU TRAVEL?</Accordion.Header>
        <Accordion.Body>
        Yes we do. We are available for Engagement Makeup around Peninsula Malaysia and Singapore.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="12" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>DO YOU HAVE TRAVEL FEE?</Accordion.Header>
        <Accordion.Body>
        Yes we do if we are about to travel out of Ipoh area. If travel time exceeds 2 hours, a hotel accomodation may be required for us.
        Please enquire for destination travel pricing.
        </Accordion.Body>
      </Accordion.Item>
      
      <Accordion.Item eventKey="13" data-aos="fade-up" data-aos-duration="1500">
        <Accordion.Header>HOW DO YOU HANDLE PAYMENT?</Accordion.Header>
        <Accordion.Body>
        We accept cash, Online Bank Transfer and selected E-Wallet.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    </div>
 <GoToTop />
 <Footer />

 <Modal style={{fontFamily:"Cormorant Garamond", color:"antiquewhite"}} show={show} onHide={handleClose}>
        <Modal.Header style={{backgroundColor:"#0f0e0ebd"}} closeButton >
          <Modal.Title> Makeup Booking Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body className=' formStyle'>

        <Form  onSubmit={message}> 
        
        <Form.Group className="mb-3" controlId="formGridName">
          <Form.Label>Name</Form.Label>
          <Form.Control required size="lg" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Name" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridPhone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control required size="lg" type='number' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridSelect1">
        <Form.Label>MakeUp Type</Form.Label>
        <Form.Select required size='lg'  value={makeup} onChange={(e) => setMakeup(e.target.value)} aria-label="Default select example">
    <option>Select MakeUp Type</option>
    <option value="wedding MakeUp">Wedding MakeUp</option>
    <option value="Engagament MakeUp">Engagement MakeUp</option>
    <option value="Dinner MakeUp">Dinner Makeup</option>
  </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridDate">
        <Form.Label>Event Date</Form.Label>
        <Form.Control required size="lg" type='date' placeholder="dd-mm-yyyy" value={date} onChange={(e) => setDate(e.target.value)}
  min="2024-03-01" max="2025-12-31" />
      </Form.Group>
     

      <Form.Group className="mb-3" controlId="formGridTime">
        <Form.Label>Time To Be Ready</Form.Label>
        <Form.Control required size="lg" type='time' placeholder="hh:mm" value={time} onChange={(e) => setTime(e.target.value)} />
      </Form.Group>

     

      <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Location</Form.Label>
      <Form.Control placeholder="Event Place" value={location} onChange={(e) => setLocation(e.target.value)}/>
    </Form.Group>

    <Form.Group className="mb-3" controlId="formGridSelect2">
        <Form.Label>Do We Need To Travel?</Form.Label>
        <Form.Select size='lg'  value={travel} onChange={(e) => setTravel(e.target.value)} aria-label="Default select example">
    <option>Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
    
  </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Enquiries</Form.Label>
        <Form.Control size="lg" as="textarea" placeholder='Message' value={enquiry} onChange={(e) => setEnquiry(e.target.value)} />
        </Form.Group>
  
        <Button  className='formButton' variant="secondary" type="submit">
          Submit
        </Button>
      </Form>

      <div className='formStyle' style={{backgroundColor:"#0f0e0ebd"}}>

      <i>Please take note: You need WhatsApp to send this form to us. </i>
      </div>

       

        </Modal.Body>
        <Modal.Footer style={{backgroundColor:"#0f0e0ebd"}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
</>
  );
};


    


export default EngagementMakeup