import React, { useEffect, useState } from 'react'; 
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4'
import FbPixel from './FbPixel';
// import {Route, Routes, useNavigate} from 'react-router-dom';
import { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.css'; 
import Navibar from './Navibar';
import Footer from './Footer';
import Gallery from './Gallery';
import FeedBack from './FeedBack';
import MakeupSelection from './MakeupSelection';

import WeddingMakeup from './WeddingMakeup';
import Carousel from 'react-bootstrap/Carousel';
import ButtonGroup from "react-bootstrap/ButtonGroup"; 
import Button from "react-bootstrap/Button"; 
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css';
import a1 from './a11.jpg'
import a2 from './a12.jpg'
import a3 from './a13.jpg'
import a from './131.jpg'
import b from './132.jpg'
import c from './133.jpg'
import b12 from './b120.png'
import b12dt from './b12dt.jpg'
import c1dt from './c1dt.jpg'
import wsup from './wsup.png'
import ig from './ig.png'
import call1 from './call1.png'
import fb from './fb.png'
import ttk from './ttk.png'
import b2 from './b2.jpg'
import b3 from './b3.jpg'
import c23 from './c23.gif'
import c2dt from './c2dt2.jpg'
import c3dt from './c3dt.jpg'
import c4 from './c4.gif'

import leftup from './leftup.png'
import leftdown from './leftdown.png'
import rightup from './rightup.png'
import rightdown from './rightdown.png'
import facialmodel from './facialmodel.png'
import facialbanner from './facialbanner.png'
import facialbanner2 from './facial banner2.png'
import facialbg2 from './facialbg2.png'

import d from './d.jpg'
import d1 from './d1.png'
import d2 from './d2.png'
import d3 from './d3.png'
import e13 from './e13.png'


import f1 from './f1.png'
import f2 from './f2.png'
import f3 from './f3.png'
import f4 from './f4.png'
import f5 from './f5.png'

import touch from './touch.png'





const Home =() => { 

  

  

  AOS.init();

  const navigate = useNavigate();

  const MakeupPage = () => {
    navigate('/MakeupSelection');
   }

   const BridalGallery = () => {
    navigate('/Gallery');
   };

   const FacialPage = () => {
    navigate('/Facial');
   };

   const EmbroPage = () => {
    navigate('/EyebrowEmbroidery');
   };

   const SareePage = () => {
    navigate('/Pleating');
   };

   const CoursePage = () => {
    navigate('/Course');
   };
  const [index, setIndex] = useState(0); 

  const handleSelect = (selectedIndex) => { 
      setIndex(selectedIndex); 
  }; 

  useEffect (() => {
    ReactGA.send("pageview");
  }, []);

  
  return ( 
    <>

    <FbPixel />
    < Navibar />



    
    
    <div style={{
      display: 'block',
      height: "100%"
    }}>
        
        <Carousel className='d-none d-md-block' activeIndex={index}
          onSelect={handleSelect}>
          <Carousel.Item interval={3000}>
            <img className="d-block w-100" src={a1}

              alt="Image One" />
           
            <h3 className="carouselCaption1">Feel Poised <br></br> and <br></br> Confident</h3>

            {/* <p className="carouselCaptionTop"> BRIDAL &#x2022; OCCASION &#x2022; EDITORIAL</p> */}
            
                     
            <button type="button" onClick={MakeupPage} className="btn btn-outline-light buttonCarousel5 ">SELECT MAKEUP</button>
             
            <button type="button" onClick={BridalGallery} className="btn btn-light buttonCarousel6 ">VIEW GALLERY</button>


          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block w-100" src={a2}

              alt="Image Two" />
            
            <h3 className="carouselCaption2">Enhance Your <br></br>  Natural <br></br> Beauty</h3>
            {/* <p className="carouselCaptionTop2"> BRIDAL &#x2022; OCCASION &#x2022; EDITORIAL</p> */}
            <button type="button" onClick={MakeupPage} className="btn btn-outline-light buttonCarousel3 ">SELECT MAKEUP</button>
            <button type="button" onClick={BridalGallery} className="btn btn-light buttonCarousel4 ">VIEW GALLERY</button>
         
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block w-100" src={a3}

              alt="Image Three" />
           
            <h3 className="carouselCaption1"> Be The <br></br> Best Version <br></br> Of You </h3>
            {/* <p className="carouselCaptionTop"> BRIDAL &#x2022; OCCASION &#x2022; EDITORIAL</p> */}
            <button type="button" onClick={MakeupPage} className="btn btn-outline-light buttonCarousel5 ">SELECT MAKEUP</button>
            <button type="button" onClick={BridalGallery} className="btn btn-light buttonCarousel6 ">VIEW GALLERY</button>

            
          </Carousel.Item>
        </Carousel>
        <Carousel className='d-md-none' activeIndex={index}
          onSelect={handleSelect}
            indicators={false}>
          <Carousel.Item interval={3000}>
            <img className="d-block w-100 rounded-bottom-5"  src={b}

              alt="Image One" />
           
            {/* <h3 className="carouselCaption1">Feel Poised <br></br> and <br></br> Confident</h3> */}

            {/* <box className="shade"></box> */}
            
                   <div className='carouselButton'> 
            <button type="button" onClick={MakeupPage} className="btn btn-light rounded-5 buttonCarousel1 ">SELECT MAKEUP</button>
             
            <button type="button" onClick={BridalGallery} className="btn btn-light rounded-5 buttonCarousel2 ">BRIDAL GALLERY</button>
            </div> 

          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block w-100 rounded-bottom-5" src={c}

              alt="Image Two" />
            
            {/* <h3 className="carouselCaption1">Enhance Your <br></br>  Natural <br></br> Beauty</h3> */}
            {/* <box className="shade"></box> */}
            <div className='carouselButton'> 
            <button type="button" onClick={MakeupPage} className="btn btn-light rounded-5 buttonCarousel1 ">SELECT MAKEUP</button>
             
            <button type="button" onClick={BridalGallery} className="btn btn-light rounded-5 buttonCarousel2 ">BRIDAL GALLERY</button>
            </div> 

          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img className="d-block w-100 rounded-bottom-5" src={a}

              alt="Image Three" />
           
            {/* <h3 className="carouselCaption1"> Be The <br></br> Best Version <br></br> Of You </h3> */}
            {/* <box className="shade"></box> */}
            <div className='carouselButton'> 
            <button type="button" onClick={MakeupPage} className="btn btn-light rounded-5 buttonCarousel1 ">SELECT MAKEUP</button>
            <button type="button" onClick={BridalGallery} className="btn btn-light rounded-5 buttonCarousel2 ">BRIDAL GALLERY</button>
            </div> 
            
          </Carousel.Item>
        </Carousel>

        
      </div>

      <br></br>

      <div className="card border-0 rounded-5 d-md-none" style={{backgroundColor:"#1a1818"}} >
  {/* <div className="row g-0">
    <div className="col-md-4"> */}
      <img src={b12}className="img-fluid rounded-start" alt="Suvarnas Bridal"></img>
    
    {/* <div className="col-md-8"> */}
    <div className="card-body rounded-bottom-5" style={{backgroundColor:"#1a1818"}}>
    <h5 className="card-title infoHome" data-aos="fade-down" style={{fontSize:"7vw",lineHeight:"0.2"}}>Hi!</h5>
        <h3 className='infoHome' data-aos="zoom-in-up" data-aos-duration="1500" style={{fontSize:"9vw"}}> I'm <b style={{color:"#b14197"}}>Suvarna</b> </h3>
        {/* <h5 className="card-title"> FOUNDER & MAKEUP ARTIST </h5> */}
        <p className="card-text-center fw-light infoHome" data-aos="fade-up" data-aos-duration="1500"> 
        I am a <b>Professional Hair and Makeup Artist</b> with over 15 years of experience in the beauty industry. 
        <br></br>
        <br data-aos="fade-up" data-aos-duration="1500"></br>
        I'm expert in creating natural, flawless and exceptional makeup and hairstyling
         for your dream wedding or extra special occasion.
        <br></br>
        <br data-aos="fade-up" data-aos-duration="1500"></br>
       My makeup and hairstyling is always individual to you and created to bring out your natural beauty.
       <br></br>
       <br data-aos="fade-up" data-aos-duration="1500"></br>
       Want me to make your day? Send me a message, I'm looking forward to working with you.
         </p>
         <ul className='text-center fw-light' style={{listStyleType:"none",paddingLeft:'0'}}>
          
            <li style={{marginTop:"10%"}}>
            < a href='tel:+60165027265' target='blank'><img src={call1} style={{margin:"5%", width:"10%"}}></img></a>
            < a href='https://wa.me/165027265' target='blank'><img src={wsup} style={{margin:"5%", width:"10%"}}></img></a>
               <a href='https://www.instagram.com/suvarnasbridalmua?igsh=MWlxNHpyM2V6MXhzeQ==' target='blank'><img src={ig}style={{margin:"5%", width:"10%"}}></img></a>
<a href='https://www.facebook.com/share/y5CJPssRqA76iX8C/?mibextid=qi2Omg' target='blank'><img src={fb}style={{margin:"5%", width:"10%"}}></img></a>
<a href='https://www.tiktok.com/@suvarnasbridalmua?_t=8kL2uG1VYcp&_r=1' target='blank'><img src={ttk}style={{margin:"5%", width:"10%"}}></img></a></li>
</ul>
</div>
      </div>
    {/* </div>
  </div>

</div> */}

      {/* <h1 className='welcome'> Welcome to <br></br> Suvarna's Bridal & HairStyles</h1> */}

      <div className="card mb-3 border-0 rounded-5 d-none d-md-block" style={{backgroundColor:"#1a1818"}}>
      <div className="row g-0">
  
    <div className="col-md-4">
      <img src={b12} className="img-fluid rounded-start" alt="Suvarnas Bridal" style={{width:'100%'}} ></img>
    </div>
<div className="col-md-8" style={{display:"flex", alignItems:'center'}}>
  <div className="card-body rounded-5" style={{backgroundColor:"#1a1818"}}>
  <h5 className="card-title infoHome" data-aos="fade-down" style={{fontSize:'4vw',lineHeight:"0.2"}}>Hi!</h5>
        <h3 className='infoHome ' data-aos="zoom-in-up" data-aos-duration="1500"  style={{fontSize:'4vw'}}> I'm <b style={{color:"#b14197"}}>Suvarna</b> </h3>
      
        <p className="card-text-center fw-light infoHome" data-aos="fade-up" data-aos-duration="1500"> 
        I am a <b>Professional Hair and Makeup Artist</b> with over 15 years of experience in the beauty industry. 
        
        <br data-aos="fade-up" data-aos-duration="1500"></br>
        I'm expert in creating natural, flawless and exceptional makeup and hairstyling
         for your dream wedding or extra special occasion.
        
        <br data-aos="fade-up" data-aos-duration="1500"></br>
       My makeup and hairstyling is always individual to you and created to bring out your natural beauty.
      <br></br>
       <br data-aos="fade-up" data-aos-duration="1500"></br>
       Want me to make your day? Send me a message, I'm looking forward to working with you.
         </p>

         <ul className='text-center fw-light' style={{listStyleType:"none",marginBottom:'0'}}>
          
            <li style={{marginTop:"10%"}}>
            < a href='tel:+60165027265' target='blank'><img src={call1} style={{width:"10%",margin:"4%"}}></img></a>
            < a href='https://wa.me/165027265' target='blank'><img src={wsup} style={{width:"10%",margin:"4%"}}></img></a>
               <a href='https://www.instagram.com/suvarnasbridalmua?igsh=MWlxNHpyM2V6MXhzeQ== ' target='blank'><img src={ig}style={{width:"10%",margin:"4%"}}></img></a>
<a href='https://www.facebook.com/share/y5CJPssRqA76iX8C/?mibextid=qi2Omg' target='blank'><img src={fb}style={{width:"10%",margin:"4%"}}></img></a>
<a href='https://www.tiktok.com/@suvarnasbridalmua?_t=8kL2uG1VYcp&_r=1' target='blank'><img src={ttk}style={{width:"10%",margin:"4%"}}></img></a></li>
</ul>
  </div>
</div>
</div>
</div>



     

<br></br>

<br></br>


<div className="card text-bg-dark rounded-4 d-md-none"style={{overflow:"hidden", marginBottom:"2%"}} onClick={FacialPage}>
  <img src={facialbg2} className="card-img" alt="Suvarnas Bridal"  ></img>
  <div className="card-img-overlay">
  <img className='banner' data-aos="fade-down" data-aos-duration="1500" src={facialbanner2} ></img>
  <img className='facialback' src={facialbg2}></img>
        <img className='facialmodel' src={facialmodel} ></img>
        
        <img className='rightup' data-aos="fade-up-right" data-aos-duration="1500" src={rightup}></img>
       
        <img className='leftdown' data-aos="fade-down-left" data-aos-duration="1500" src={leftdown}></img>
        
        <img className='leftup' data-aos="fade-up-left" data-aos-duration="1500" src={leftup}></img>
        
        <img className='rightdown' data-aos="fade-down-right" data-aos-duration="1500"  src={rightdown}></img>
        
        
        
        <button className='facialbtn' > Select <br></br> Facial <br></br> Type </button>
        <img className= 'facialTouch' src={touch}></img>
        

      </div>
</div>


<div className="card text-bg-dark rounded-4 border-0 d-md-none" style={{overflow:"hidden", marginBottom:"2%"}}onClick={EmbroPage}>
  <img src={c23} className="card-img" alt="Suvarnas Bridal" ></img>
  
        <button className='embroBtn'data-aos="fade-up" data-aos-duration="2000" > View <br></br> Details </button>
        <img className= 'eyeTouch' src={touch}></img>
       
        <button className='embroBtn2'data-aos="fade-up" data-aos-duration="1000" > RM250 <br></br> ONLY </button>
  <div className="card-img-overlay">
  <p className="fw-light embroInfo" data-aos="fade-up" data-aos-duration="1000">&#x2022; Ombre EyeBrow Embroidery <br></br>
  &#x2022; Free touch Up <br></br>
  &#x2022; Free Vitamin C Cream  </p>

      </div>
</div>



<div className="card text-bg-dark rounded-4 d-md-none" style={{overflow:"hidden"}} onClick={SareePage}>
  <img src={d} className="card-img" alt="Suvarnas Bridal" ></img>
  <div className="card-img-overlay">
  {/* <img className='banner' data-aos="fade-down" data-aos-duration="1500" src={facialbanner}></img> */}
  {/* <img className='facialback' src={facialbg2}></img> */}
        <img className='sareeLeft' data-aos="fade-up" data-aos-duration="1500" src={d1}></img>
        
        <img className='sareeRight' data-aos="fade-up" data-aos-duration="1500" src={d2}></img>
       
        <img className='sareeCenter' data-aos="fade-up" data-aos-duration="1500" src={d3}></img>

        <h1 className='sareeCap'>SAREE PRE - PLEATING</h1>
        <p className="fw-light sareeInfo" data-aos="fade-up" data-aos-duration="1200"> Starts from <br></br> as Low as <br></br> RM30 
   </p>

   <button type="button" className="btn btn-light rounded-5 sareeButtonE" data-aos="fade-up" data-aos-duration="2000">EXPLORE</button>
   <img className= 'sareeTouch' src={touch} ></img>
        
       
      </div>
</div>

<div className=' d-none d-md-block'>
<div className="card-group">
  <div className="card rounded-5">
    <img src={c1dt} className="card-img-top rounded-5" alt="Suvarnas Bridal">
    
    </img>
    
        <button className='facialbtndt' data-aos="fade-up" data-aos-duration="1200" onClick={FacialPage}> Facial  Type </button>
        
  </div>
  <div className="card rounded-5">
    <img src={c2dt} className="card-img-top rounded-5" alt="Suvarnas Bridal"></img>
   
        <button className='embroBtnDt'data-aos="fade-up" data-aos-duration="2000" onClick={EmbroPage}>View <br></br> Details </button>
       
        <button className='embroBtn2Dt'data-aos="fade-up" data-aos-duration="1000" > RM250 <br></br> ONLY </button>
        <div className="card-img-overlay">
  <p className="fw-light embroInfoDt" data-aos="fade-up" data-aos-duration="1000">&#x2022; Ombre EyeBrow Embroidery <br></br>
  &#x2022; Free touch Up <br></br>
  &#x2022; Free Vitamin C Cream  </p>

      </div>
  </div>
  <div className="card rounded-5" onClick={SareePage}>
    <img src={c3dt} className="card-img-top rounded-5" alt="Suvarnas Bridal"></img>
    <p className="fw-light sareeInfoDt " data-aos="fade-up" data-aos-duration="1200"> Starts from <br></br> as Low as <br></br> RM30 
   </p>
    
  </div>
</div>

</div>

<br></br>



<div className="card border-0 rounded-5 d-md-none" style={{backgroundColor:"#1a1818"}}>
        
<img src={e13} className="img-fluid rounded-start" alt="Suvarnas Bridal" style={{Width: "100%"}} onClick={CoursePage}></img>
{/* <div className='courseClick'> */}
{/* <button className='courseButton'>Register Now</button> */}
{/* </div> */}
  <div className="card-body rounded-5" style={{backgroundColor:"#1a1818"}}>
  <h5 className="card-title infoHome" data-aos="fade-down" style={{fontSize:"11vw",lineHeight:"0.2",color:"#b14197"}}>Suvarnas</h5>
        <h3 className='infoHome' data-aos="zoom-in-up" data-aos-duration="1500" style={{fontSize:"9vw",color:"#b14197"}}>Training Centre</h3>
        <br></br>
        {/* <h5 className="card-title"> FOUNDER & MAKEUP ARTIST </h5> */}
        <p className="card-text-center fw-light infoHome" data-aos="fade-up" data-aos-duration="1000">Our Courses aim to ensure all students receive the most 
        in depth knowledge on all aspects they are learning. Our teaching are detailed and include everything you need to know from theory to 
        practical techniques. </p>
         
  </div>
</div>

<div className="card mb-3 border-0 rounded-5 d-none d-md-block" style={{backgroundColor:"#1a1818"}}  onClick={CoursePage}>
      <div className="row g-0">
  
    <div className="col-md-4">
      <img src={e13} className="img-fluid rounded-end" alt="Suvarnas Bridal" style={{width:'100%'}}></img>
    </div>
<div className="col-md-8" style={{display:"flex", alignItems:'center'}}>
  <div className="card-body rounded-5" style={{backgroundColor:"#1a1818"}}>
  <h5 className="card-title infoHome" data-aos="fade-down" style={{fontSize:'4vw',color:"#b14197"}}>Suvarnas</h5>
        <h3 className='infoHome' data-aos="zoom-in-up" data-aos-duration="1500" style={{fontSize:'4vw',color:"#b14197"}}>Training Centre</h3>
        <br></br>
        {/* <h5 className="card-title"> FOUNDER & MAKEUP ARTIST </h5> */}
        <p className="card-text-center fw-light infoHome" data-aos="fade-up" data-aos-duration="1000">Our Courses aim to ensure all students receive the most 
        in depth knowledge on all aspects they are learning. Our teaching are detailed and include everything you need to know from theory to 
        practical techniques. </p>

  </div>
</div>
</div>
</div>







<br></br>
<h1 className='mb-0 rounded-top-5 title pwuBg' style={{paddingTop:"4%"}}> Product We Use</h1>
<div className="row gx-0 justify-content-center row-cols-1 row-cols-md-3 g-4-gx-0 rounded-bottom-5 pwuBg">

  <div className="col">
    <div className="card bg-transparent border-0">
      <img src={f1} className="card-img-top"  data-aos="zoom-in-up" data-aos-duration="1500" alt="Suvarnas Bridal" ></img>
      </div>
    </div>
 
  <div className="col">
    <div className="card bg-transparent border-0">
      <img src={f2} className="card-img-top" data-aos="zoom-in-up" data-aos-duration="1500" alt="Suvarnas Bridal"></img>
      
    </div>
  </div>

  <div className="col">
    <div className="card bg-transparent border-0">
      <img src={f3} className="card-img-top" data-aos="zoom-in-up" data-aos-duration="1500" alt="Suvarnas Bridal"></img>
      
    </div>
  </div>

  <div className="col">
    <div className="card bg-transparent border-0">
      <img src={f4} className="card-img-top" data-aos="zoom-in-up" data-aos-duration="1500" alt="Suvarnas Bridal"></img>
      
    </div>
  </div>
  
  <div className="col">
    <div className="card bg-transparent border-0">
      <img src={f5} className="card-img-top" data-aos="zoom-in-up" data-aos-duration="1500" alt="Suvarnas Bridal" ></img>
      
    </div>
  </div>
</div>
{/* </div> */}

<br></br>




<br></br>

<FeedBack/>

<br></br>

<Footer />






</>
);
}

export default Home;


